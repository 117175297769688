import React from 'react';
import Button, {BUTTON_STATUSES} from '@components/Button';
// @ts-ignore
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '@src/styles/landingProducts.scss';
import {useClickunder} from '@src/pages/LandingPage/hooks/useClickunder';

import phoneRegImg from '@images/simpleRegistration/phone.png';
import blankImg from '@images/simpleRegistration/blank.png';
import cameraImg from '@images/simpleRegistration/camera.png';
import houseImg from '@images/simpleRegistration/house.png';

import styles from './simpleRegistration.module.scss';

const simpleRegistrationArr = [
    {
        title: 'Телефон и код',
        description1: 'Чтобы воспользоваться нашими продуктами, создайте личный кабинет - это займет всего несколько минут.',
        description2: 'Для начала просто укажите свой номер телефона и подтвердите его кодом из СМС.',
        image: phoneRegImg,
    },
    {
        title: 'Общая анкета',
        description1: 'Введите свои основные идентификационные данные: ваши имя, дату рождения, паспортные данные и электронную почту.',
        description2: 'Эта анкета нужна не только для создания профиля - она позволит нам надежно защитить вас от мошенников.',
        image: blankImg,
    },
    {
        title: 'Селфи с паспортом',
        description1: 'Другим надежным фактором защиты аккаунта станут ваши фото, благодаря которым мы всегда сможем отличить вас от злоумышленника.',
        description2: 'Не беспокойтесь - все фото надежно защищены и будут использованы исключительно для верификации.',
        image: cameraImg,
    },
    {
        title: 'Данные о регистрации',
        description1: 'Вы почти у цели! На последнем шаге регистрации вам предстоит указать данные о постоянной регистрации из соответствующей страницы в паспорте.',
        description2: 'На этом все - теперь вы сможете получать займы быстро и полностью онлайн!',
        image: houseImg,
    },
];

const sliderOptions = {
    dots: true,
    arrows: false,
    centerMode: true,
    infinite: false,
    centerPadding: `0vw`,
    slidesToShow: 1,
    variableWidth: true,
    speed: 400,
    initialSlide: 0,
};

export const SimpleRegistration = () => {
    const { handleClickunderButtonClick} = useClickunder();

    return (
      <div className={styles.container}>
        <div className={styles.upBlock}>
          <p className={styles.commonTitleWithMargin}>Простая регистрация</p>
          <Button
            className={styles.button}
            status={BUTTON_STATUSES.NEW_PINK}
            onClick={handleClickunderButtonClick}
          >
            Зарегистрироваться
          </Button>
        </div>
        <div className={styles.downBlock}>
          {simpleRegistrationArr.map((el, regIndex) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={regIndex} className={styles.item}>
              <div className={styles.itemUpBlock}>
                <p className={styles.itemTitle}>{el.title}</p>
                <img src={el.image} alt={el.title}/>
              </div>
              <p className={styles.itemDescription}>{el.description1}</p>
              <p className={styles.itemDescription}>{el.description2}</p>
            </div>
          ))}
        </div>
        <div className={styles.downBlockMobile}>
          <Slider
            {...sliderOptions}
            className="component-products__carousel"
          >
            {simpleRegistrationArr.map((el, regIndex) => (
                // eslint-disable-next-line react/no-array-index-key
              <div key={regIndex} className={`component-products__carousel__item ${styles.item}`}>
                <div className={styles.itemUpBlock}>
                  <p className={styles.itemTitle}>{el.title}</p>
                  <img src={el.image} alt={el.title}/>
                </div>
                <p className={styles.itemDescription}>{el.description1}</p>
                <p className={styles.itemDescription}>{el.description2}</p>
                <Button
                  className={styles.mobileButton}
                  status={BUTTON_STATUSES.NEW_PINK}
                  onClick={handleClickunderButtonClick}
                >
                  Зарегистрироваться
                </Button>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    );
};
