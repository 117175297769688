import React from 'react';
import {Link, useNavigate} from 'react-router-dom';

import {ACCOUNT_MODERATION_STATUSES} from '@src/constants';
import {useSelector} from 'react-redux';

import styles from './GetForFreeButton.module.scss';

export default function GetForFreeButton() {

    const navigate = useNavigate();

    // @ts-ignore
    const { isAuthenticated, accountModerationStatus } = useSelector(({ auth, profile }) => ({
        isAuthenticated: auth.isAuthenticated,
        accountModerationStatus: profile.accountModerationStatus,
    }));

    const clickHandler = () => {
        window.ym(87515319,'reachGoal','free_button_click');


        if (isAuthenticated && accountModerationStatus === ACCOUNT_MODERATION_STATUSES.REGISTERED) {
            navigate('/sign_up');
        } else if (isAuthenticated) {
            navigate('/profile/add-contract');
        } else {
            window.ym(87515319, 'reachGoal', 'reg_2_start');
            window.dataLayer.push({'event': 'reg_2_start'});
            window.location.href = '/sign_up/user_info';
        }
    }

    return (
      <div className={styles.getForFreeButton} onClick={clickHandler}>
        Получить бесплатно
      </div>
    );
}
