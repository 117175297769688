import React from 'react';
import classnames from 'classnames';
import { SupportQuestions } from '@src/pages/Support';

import './Support.scss';
import Button, {BUTTON_STATUSES} from '@components/Button';

import {useClickunder} from '@src/pages/LandingPage/hooks/useClickunder';

import SupportMobile from '../SupportMobile';

const Support = () => {
    const { handleClickunderButtonClick} = useClickunder();

return (
  <div className={classnames('container')}>
    <section className={classnames('component-support')}>
      <p className={classnames('component-support_title')}>Часто задаваемые вопросы</p>
      <SupportQuestions />
    </section>
    <section className={classnames('component-support', 'component-support_mobile')}>
      <SupportMobile key="support" />
    </section>
    <Button
      className={classnames('button')}
      status={BUTTON_STATUSES.NEW_PINK}
      onClick={handleClickunderButtonClick}
    >
      Начать пользоваться
    </Button>
  </div>
)};

export default Support;
