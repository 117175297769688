import React from 'react';

import styles from './Loader.module.scss';

const Loader = ({text = 'Загрузка данных'}) => (
  <div className={styles.LoadingBlock}>
    <div className={styles.LoadingPoints}>
      <div />
      <div />
      <div />
      <div />
    </div>
    <p className={styles.LoadingText}>{text}</p>
  </div>
  );

export default Loader;
