import React, {useCallback, useEffect, useState} from 'react';

import Button, {BUTTON_STATUSES} from '@components/Button';

import {getSearchParams} from '@src/utils/searchParams';

import {useNavigate} from 'react-router-dom';

import {setSubscription} from '@src/api';

import Loader from '@src/pages/SignUpV2/Loader/Loader';

import Header from './components/Header'
import Footer from './components/Footer';
import styles from './Mailing.module.scss';

const MailingPage = () => {
  const [clientId, setClientId] = useState('')
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const clientIdParam = getSearchParams('clientId')

    if (clientIdParam) {
      setClientId(clientIdParam)
    } else {
      navigate('/404')
    }
  }, [clientId, navigate])

  const mailingOk = useCallback(async () => {
    setIsLoading(true)
      try {
        const {result} = await setSubscription({clientId})

        if (result && result?.data?.result) {
          setResult(result)
        }
      } catch (e) {
        console.error(e)
      } finally {
        setIsLoading(false)
      }
  }, [clientId])


  const mailingCancel = () => navigate('/')

  if (isLoading) {
    return <Loader />
  }

  return (<section className={styles.MailingPage}>
    <div className={styles.Content}>
      <Header/>
      <div className={styles.InfoBlock}>
        <div className={styles.Padding}>
          {!result ? (<>
            <div className={styles.Title}>Отписка от рассылки</div>
            <div className={styles.Text}>На этой странице вы можете отказаться от рассылки, однако вы упустите
              самые выгодные
              предложения
            </div>
            <div className={styles.AuthFooter}>
              <Button status={BUTTON_STATUSES.ACTIVED} onClick={mailingOk} className="">
                Отписаться
              </Button>
              <Button status={BUTTON_STATUSES.SMOOTHED} onClick={mailingCancel} className="">
                Отмена
              </Button>
            </div>
          </>) : (<>
            <div className={styles.Title}>Отписка от рассылки</div>
            <div className={styles.Text}>Вы успешно отписались от рассылки
            </div>
            <div className={styles.AuthFooter}>
              <Button status={BUTTON_STATUSES.ACTIVED} onClick={mailingCancel} className="">
                На главную
              </Button>
            </div>
          </>)}
        </div>
      </div>
    </div>
    <Footer withBackground/>
  </section>)
};
export default MailingPage;
