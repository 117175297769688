import React, {useState} from 'react';

import {LandingRangeForCreditLine} from '@components/LandingRangeForCreditLine/LandingRangeForCreditLine';
import Button, {BUTTON_STATUSES} from '@components/Button';
import {useClickunder} from '@src/pages/LandingPage/hooks/useClickunder';

import styles from './creditLine.module.scss';

const getRating = (value: number) => {
    const min = 15000;
    const max = 300000;

    const normalizedValue = (value - min) / (max - min);

    return Math.min(5, Math.max(1, Math.ceil(normalizedValue * 5)));
};

// eslint-disable-next-line react/require-default-props
const StarIcon = ({className}: {className?: string}) => (
  <svg className={`${styles.starIcon} ${className || ''}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
    <path d="M11.5383 1.41194C11.7091 1.0013 12.2909 1.0013 12.4617 1.41194L15.0068 7.53125C15.2228 8.0506 15.7112 8.40545 16.2719 8.4504L22.8782 8.98003C23.3216 9.01557 23.5013 9.56882 23.1636 9.85816L18.1303 14.1697C17.7031 14.5356 17.5165 15.1098 17.647 15.6569L19.1848 22.1036C19.288 22.5362 18.8173 22.8781 18.4378 22.6463L12.7819 19.1917C12.3019 18.8985 11.6981 18.8985 11.2181 19.1917L5.5622 22.6463C5.18265 22.8781 4.71203 22.5362 4.81522 22.1036L6.35298 15.6569C6.48349 15.1098 6.29693 14.5356 5.86975 14.1697L0.836443 9.85816C0.498674 9.56882 0.678438 9.01557 1.12176 8.98003L7.72807 8.4504C8.28875 8.40545 8.77717 8.0506 8.99318 7.53125L11.5383 1.41194Z" stroke="#F82E3A"/>
  </svg>
);

const StarsIconContainer = ({creditLine}: {creditLine: number}) => (
  <>
    <div className={styles.starsBlock}>
      {new Array(5).fill(null).map((_, starIndex) => (
                // eslint-disable-next-line react/no-array-index-key
        <StarIcon className={getRating(creditLine) > starIndex ? styles.starIconFilled : undefined} key={starIndex} />
            ))}
    </div>
    <p className={styles.downBlockRightDescription}>Вы можете оформлять более одного займа в рамках кредитной линии в случае, если по конкретному продукту вы получили максимум средств!</p>
  </>
);

export const CreditLine = () => {
    const { handleClickunderButtonClick} = useClickunder();
    const [creditLine, setCreditLine] = useState(120000);

    return (
      <div className={styles.container}>
        <p className={styles.title}>Кредитная линия</p>
        <div className={styles.downBlock}>
          <div className={styles.downBlockLeft}>
            <p className={styles.downBlockLeftTitle}>Настройте свою кредитную линию</p>
            <p className={styles.downBlockLeftDescription}>Мы оцениваем платежеспособность и устанавливаем для вас определенный лимит - от 15 000 до 300 000 рублей. Лимит можно постоянно увеличивать - двигайте слайдер, чтобы узнать, что для этого нужно делать.</p>
            <LandingRangeForCreditLine
              minValue={15000}
              minTitle="15 000 ₽"
              maxValue={300000}
              maxTitle="300 000 ₽"
              onChange={(value) => setCreditLine(+value)}
              value={creditLine}
            />
            <div className={styles.downBlockRightMobile}>
              <StarsIconContainer creditLine={creditLine}/>
            </div>
            <Button
              className={styles.downBlockLeftButton}
              status={BUTTON_STATUSES.NEW_PINK}
              onClick={handleClickunderButtonClick}
            >
              Узнать свой лимит
            </Button>
          </div>
          <div className={styles.downBlockRight}>
            <StarsIconContainer creditLine={creditLine}/>
          </div>
        </div>
      </div>
    );
};
