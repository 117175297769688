import InputRange, {Range} from 'react-input-range';
import React from 'react';

import 'react-input-range/lib/css/index.css';
import styles from './LandingRange.module.scss';

interface IProps {
    value: number;
    minValue: number;
    minTitle: string;
    maxValue: number;
    maxTitle: string;
    step: number;
    onChange: (value: number | Range) => void;
}

export const LandingRange = ({minValue, minTitle, maxValue, maxTitle, onChange, value, step}: IProps) => (
  <div className={styles.container}>
    <InputRange
      minValue={minValue}
      maxValue={maxValue}
      value={value}
      step={step}
      onChange={onChange}
            // @ts-ignore
      classNames={{
                track: styles.customTrack,
                activeTrack: styles.customActiveTrack,
                slider: styles.customSlider,
                labelContainer: styles.customLabel,
            }}
        />
    <div className={styles.rangeLabels}>
      <p className={styles.rangeLabel}>{minTitle}</p>
      <p className={styles.rangeLabel}>{maxTitle}</p>
    </div>
  </div>
);

interface ITitleDectRangeBlockProps extends IProps {
    title: string;
    description: string;
}

export const TitleDectRangeBlock = ({title, description, ...rest}: ITitleDectRangeBlockProps) => (
  <div className={styles.dectRangeBlock}>
    <p className={styles.miniTitle}>{title}</p>
    <p className={styles.amountDescription}>{description}</p>
    <LandingRange {...rest} />
  </div>
);
