import React from 'react';

import Button, {BUTTON_STATUSES} from '@components/Button';
import {useClickunder} from '@src/pages/LandingPage/hooks/useClickunder';

import styles from './getMoneyTop.module.scss';

export const GetMoneyTop = () => {
    const { handleClickunderButtonClick} = useClickunder();

return (
  <div className={styles.container}>
    <p className={styles.title}>До 300 000 ₽<br/>полностью онлайн</p>
    <p className={styles.description}>Моментальные займы с быстрой<br/>регистрацией</p>
    <div className={styles.infoMobileBlock}>
      <p className={styles.infoTitleMobile}>До 300 000 ₽</p>
      <p className={styles.infoDescriptionMobile}>полностью онлайн</p>
    </div>
    <Button
      className={styles.button}
      status={BUTTON_STATUSES.NEW_PINK}
      onClick={handleClickunderButtonClick}
    >
      Получить  на карту
    </Button>
  </div>
)};
