import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import LogoWhiteLogo from '@src/assets/images/header_logo_white.svg'

import styles from './Header.module.scss';

const Header = () => {

  const navigate = useNavigate();

  const redirectToMain = () => {
    navigate('/')
  }

  return(
    <div className={styles.LoginHeaderBlock}>
      <div className={styles.headerWrapper}>
        <NavLink to="/">
          <img src={LogoWhiteLogo} alt="" className={styles.headerLogo}/>
        </NavLink>
      </div>
    </div>
  );
}

export default Header;
