import React, {CSSProperties} from 'react';

import VisaIcon from '@images/PaymentSystems/VisaIcon.svg';
import MasterCardIcon from '@images/PaymentSystems/MasterCardIcon.svg';
import MirIcon from '@images/PaymentSystems/MirIcon.svg';
import SBPIcon from '@images/PaymentSystems/SBP.svg';

import styles from './iconsBlock.module.scss';

const iconsArr = [
    {icon: VisaIcon, width: 105.18, mobileWidth: 74.24},
    {icon: MasterCardIcon, width: 80.89, mobileWidth: 51.77},
    {icon: MirIcon, width: 120.12, mobileWidth: 84.79},
    {icon: SBPIcon, width: 91.6, mobileWidth: 56.37},
];

interface CustomStylesIcons extends CSSProperties {
    '--width': string;
    '--mobileWidth': string;
}

export const IconsBlock = () => (
  <div className={styles.container}>
    <p className={styles.title}>Вы можете получить деньги без переплат - мгновенно и на любой счет</p>
    <div className={styles.downBlock}>
      {iconsArr.map((el, iconIndex) => {
            const iconsStyles: CustomStylesIcons = {
                '--width': `${el.width}px`,
                '--mobileWidth': `${el.mobileWidth}px`,
            };

            return (
                // eslint-disable-next-line react/no-array-index-key
              <img style={iconsStyles} className={styles.image} alt={`icon ${iconIndex}`} key={iconIndex} src={el.icon} />
            )})}
    </div>
  </div>
);
