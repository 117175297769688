import React, {useEffect} from 'react';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import { cn } from '@bem-react/classname';
import { PopupProvider } from '@src/components/Popup';

import {targetHandler} from '@src/pages/LandingPage/targetHelpers';

import './LandingLayout.scss';
import Footer from '@src/components/Footer'

import Subnav from '@src/layouts/MainLayout/components/Subnav'

import {useSelector} from 'react-redux';

import {ACCOUNT_MODERATION_STATUSES} from '@src/constants';

import Header from './components/Header'

export const componentClassName = cn('Layout-Main');

export default function LandingLayout() {
  const { pathname } = useLocation();
  const { isAuthenticated,  accountModerationStatus} = useSelector(({ auth, profile }) => ({
      isAuthenticated: auth.isAuthenticated,
      accountModerationStatus: profile.accountModerationStatus,
  }));
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  useEffect(
    () => {
      targetHandler()
    },
    [],
  )

    useEffect(() => {
        if (isAuthenticated && (accountModerationStatus === ACCOUNT_MODERATION_STATUSES.REGISTERED
            || accountModerationStatus === ACCOUNT_MODERATION_STATUSES.UNCOMPLETED_REGISTRATION)) {
            navigate('/sign_up')
        }
    }, [accountModerationStatus, isAuthenticated, navigate]);


    useEffect(() => {
        if (isAuthenticated && accountModerationStatus !== ACCOUNT_MODERATION_STATUSES.UNCOMPLETED_REGISTRATION) {
            navigate('/profile/add-contract');
        }
    }, [accountModerationStatus, isAuthenticated, navigate])

  return (
    <PopupProvider>
      <Header />
      <div className={componentClassName('Content-Block')}>
        <Outlet />
      </div>
      <Footer />
    </PopupProvider>
  );
}
