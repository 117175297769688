import React, {useCallback, useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@src/assets/images/Subnav/Menu.svg';
import ParlourIcon from '@src/assets/images/Subnav/Parlour.svg';
import QuestionsIcon from '@src/assets/images/Subnav/Questions.svg';
import ReceiveIcon from '@src/assets/images/Subnav/Receive.svg';
import SupportIcon from '@src/assets/images/Subnav/Support.svg';
import Button from '@src/components/Button';



import './Subnav.scss';
import {ACCOUNT_MODERATION_STATUSES} from '@src/constants';
import {useSelector} from 'react-redux';
import cookie from 'js-cookie';

import {useClickunder} from '@src/pages/LandingPage/hooks/useClickunder';

import Menu from './components/Menu';
import { Context } from './context';
import GetForFreeButton from './components/GetMoneyButton/GetForFreeButton';

const Subnav = ({SHOW_BUTTON = true}) => {

  const navigate = useNavigate();
  const [menuState, setMenuState] = useState(false);
  const utmSource = cookie.get('AGENT');
  const [isShowGetForFreeButton] = useState(false);
  const NEW_BUTTON = true;
  const { handleClickunderButtonClick } = useClickunder()

  const hideMenu = () => {
    setMenuState(false);
  };

  const { isAuthenticated, accountModerationStatus } = useSelector(({ auth, profile }) => ({
    isAuthenticated: auth.isAuthenticated,
    accountModerationStatus: profile.accountModerationStatus,
  }));

  const handleClick = useCallback(() => {
    window.ym(87515319, 'reachGoal', 'click_get_btn')
    if (isAuthenticated && accountModerationStatus === ACCOUNT_MODERATION_STATUSES.REGISTERED) {
      navigate('/sign_up');
    } else if (isAuthenticated) {
      navigate('/profile/add-contract');
    } else {
      window.location.href = '/sign_up/phone';
    }
  }, [accountModerationStatus, isAuthenticated, navigate])

  useEffect(() => {
    function checkSlide() {
      const creditLine = document.getElementById('creditLine');
      const subnav = document.querySelector('.subnav');
      const windowHeight = window.innerHeight;
      const elementTop = creditLine?.getBoundingClientRect().top;

      if (subnav && elementTop < windowHeight) {
        subnav.classList.add('slide');
      } else {
        subnav.classList.remove('slide');
      }
    }
    function checkSlideHidden() {
      const creditLine = document.getElementById('creditLine');
      const subnavHide = document.querySelector('.subnavHide');
      const windowHeight = window.innerHeight;
      const elementTop = creditLine?.getBoundingClientRect().top;

      if (subnavHide && elementTop < windowHeight) {
        subnavHide.classList.add('slideAway');
      } else {
        subnavHide.classList.remove('slideAway');
      }

    }
    if (isShowGetForFreeButton) {
      if (!SHOW_BUTTON && NEW_BUTTON) window.addEventListener('scroll', checkSlideHidden);
      if (SHOW_BUTTON && NEW_BUTTON) window.addEventListener('scroll', checkSlide);
    }

    return () => {
      if (!SHOW_BUTTON && NEW_BUTTON) {
        window.removeEventListener('scroll', checkSlideHidden);
      }
      if (SHOW_BUTTON && NEW_BUTTON) {
        window.removeEventListener('scroll', checkSlide);
      }
    };
  }, [NEW_BUTTON, SHOW_BUTTON, isShowGetForFreeButton]);

  if (isShowGetForFreeButton && !SHOW_BUTTON) {
    return (
      <nav className={`subnavHide ${!NEW_BUTTON && 'slideAway'}`}>
        <ul className="subnav__tags">
          <li className="subnav__item">
            <Button
              className="subnav__link"
              onClick={() => setMenuState(!menuState)}
              >
              <div className="subnav__icon">
                <img src={MenuIcon} alt="Menu"/>
              </div>
              <p>Меню</p>
            </Button>
          </li>
          <li className="subnav__item">
            <Button
              className="subnav__link"
              onClick={() => navigate('/login')}
              >
              <div className="subnav__icon">
                <img src={ParlourIcon} alt="Parlour"/>
              </div>
              <p>Кабинет</p>
            </Button>
          </li>
          <li className="subnav__item">
            <Button
              className="subnav__link"
              onClick={handleClickunderButtonClick}
            >
              <div className="subnav__icon">
                <img src={ReceiveIcon} alt="Receive"/>
              </div>
              <p>Получить</p>
            </Button>
          </li>
          <li className="subnav__item">
            <a className="subnav__link" href="#stepsupport">
              <div className="subnav__icon">
                <img src={QuestionsIcon} alt="Questions"/>
              </div>
              <p>Вопросы</p>
            </a>
          </li>
          <li className="subnav__item">
            <a
              className="subnav__link"
              href='#feedback'
              >
              <div className="subnav__icon">
                <img src={SupportIcon} alt="Support"/>
              </div>
              <p>Помощь</p>
            </a>
          </li>
        </ul>
      </nav>
    )
  }

  return (
    <Context.Provider
      value={{
            hideMenu,
          }}
      >
      {menuState && <Menu key='mainLayout'/>}
      <nav className={`subnav ${!NEW_BUTTON && 'slide'}`}>
        {isShowGetForFreeButton && <GetForFreeButton/>}
        <ul className="subnav__tags">
          <li className="subnav__item">
            <Button
              className="subnav__link"
              onClick={() => setMenuState(!menuState)}
              >
              <div className="subnav__icon">
                <img src={MenuIcon} alt="Menu"/>
              </div>
              <p>Меню</p>
            </Button>
          </li>
          <li className="subnav__item">
            <Button
              className="subnav__link"
              onClick={() => navigate('/login')}
              >
              <div className="subnav__icon">
                <img src={ParlourIcon} alt="Parlour"/>
              </div>
              <p>Кабинет</p>
            </Button>
          </li>
          <li className="subnav__item">
            <Button
              className="subnav__link"
              onClick={handleClickunderButtonClick}
              >
              <div className="subnav__icon">
                <img src={ReceiveIcon} alt="Receive"/>
              </div>
              <p>Получить</p>
            </Button>
          </li>
          <li className="subnav__item">
            <a className="subnav__link" href="#stepsupport">
              <div className="subnav__icon">
                <img src={QuestionsIcon} alt="Questions"/>
              </div>
              <p>Вопросы</p>
            </a>
          </li>
          <li className="subnav__item">
            <a
              className="subnav__link"
              href='#feedback'
              >
              <div className="subnav__icon">
                <img src={SupportIcon} alt="Support" />
              </div>
              <p>Помощь</p>
            </a>
          </li>
        </ul>
      </nav>
    </Context.Provider>
  );
};

export default Subnav;
