import {useCallback, useState} from 'react';
import {REDIRECT_LINK} from '@src/constants';

export const useClickunder = () => {
    const [hasClickunder, setHasClickunder] = useState<boolean>(true);


    const handleClickunderButtonClick = useCallback((e: Event) => {
        e.preventDefault();
        setTimeout(() => {
            window.location.href = REDIRECT_LINK;
            window.ym(87515319, 'reachGoal', 'reg_2_start');
            window.dataLayer.push({'event': 'reg_2_start'});
        }, 1e3)
        window.open('/sign_up', '_blank')
    }, [])


    return {
        hasClickunder,
        handleClickunderButtonClick
    }
}
