import React, {CSSProperties, useEffect} from 'react';

import {useSelector} from 'react-redux';

import styles from './LoanLimitProgressBar.module.scss';

interface CustomStyles extends CSSProperties {
  '--fill-percentage': string;
}

interface IProps {
  className?: string;
}

// eslint-disable-next-line react/require-default-props
export const LoanLimitProgressBar = ({className}: IProps) => {
    // @ts-ignore
    const { creditLine } = useSelector<any>(({ auth, profile }) => ({
        isAuthenticated: auth.isAuthenticated,
        accountModerationStatus: profile.accountModerationStatus,
        creditLine: profile.creditLine
    }));
    const currentLimit = creditLine?.usedLimit || 0;
    const maxLimit = creditLine?.userCreditLimit || 0;
    const percents = currentLimit ? currentLimit/maxLimit * 100 : 0;

    const inlineStyles: CustomStyles = {
        '--fill-percentage': `${percents}%`,
    };

    useEffect(() => {
        if (window.localStorage.getItem('regForLimitStart') && maxLimit > 0) {
            window.ym(87515319,'reachGoal','get_positive_limit');
            window.dataLayer.push({'event': 'get_positive_limit'});
            window.localStorage.removeItem('regForLimitStart');
        }
    }, [maxLimit]);

  return (
    <div className={`${styles.loanLimitProgressBar} ${className}`}>
      <div style={inlineStyles} className={styles.emptyBar} />
      <div className={styles.description}>
        Используется {currentLimit} Р из общего лимита {maxLimit} Р
      </div>
    </div>
  );
}
