import React, { CSSProperties, useState } from 'react';

import { AMOUNTS, BIDS, PRODUCTS, TIME_TITLES } from '@src/constants';
import Button, { BUTTON_STATUSES } from '@components/Button';
import { TitleDectRangeBlock } from '@components/LandingRange/LandingRange';
import { useClickunder } from '@src/pages/LandingPage/hooks/useClickunder';

import styles from './ourProducts.module.scss';

const productsObj = {
  // [PRODUCTS.LOAN_WITH_GRACE_PERIOD.key]: {
  //     name: PRODUCTS.LOAN_WITH_GRACE_PERIOD.key,
  //     title: PRODUCTS.LOAN_WITH_GRACE_PERIOD.title,
  //     description: 'Короче, займ - это заем на минимальный срок. Моментальное зачисление и возможность пользоваться средствами до 7 дней абсолютно бесплатно! Выручает, когда не хватило денег до зарплаты, или случился небольшой кассовый разрыв. Максимальная сумма займа - 30 000 рублей.',
  //     loanAmount: 10000,
  //     minAmount: 3000,
  //     maxAmount: 30000,
  //     loanTerm: 1,
  //     additionalDescription: 'Наш ответ на то, что вы привыкли первым делом видеть в любых МФО. Мы специализируемся на крупных займах на долгий срок, но если вам нужна небольшая сумма на несколько дней - мы не можем вам отказать. Договор оформляется на срок от 0 до 21 дня по ставке от 0,8% в день. При займе на срок до 7 дней - проценты не начисляются!',
  //     timeTitle: TIME_TITLES.MATURITY,
  // },
  [PRODUCTS.BEFORE_SALARY.key]: {
    name: PRODUCTS.BEFORE_SALARY.key,
    title: PRODUCTS.BEFORE_SALARY.title,
    description:
      'До зарплаты - это самый простой и быстрый заем в линейке наших продуктов. Вы получаете средства и через месяц возвращаете. Этот вариант удобен, когда не хватило денег до зарплаты, или случился небольшой кассовый разрыв. Максимальная сумма займа - 30 000 рублей.',
    loanAmount: 10000,
    minAmount: 3000,
    maxAmount: 30000,
    loanTerm: 1,
    additionalDescription:
      'Вы единовременно погашаете заем и проценты за пользование средствами через месяц после оформления займа. Договор оформляется на срок 30 дней по ставке от 0,2% до 0,8% в день.',
    timeTitle: TIME_TITLES.MATURITY,
  },
  [PRODUCTS.INSTALLMENT.key]: {
    name: PRODUCTS.INSTALLMENT.key,
    title: PRODUCTS.INSTALLMENT.title,
    description:
      'Стандарт - это аналог классического банковского кредита с равными ежемесячными платежами. Платеж состоит из частичной оплаты основного долга и оплаты процентов за текущий период. Вы можете заключить договор на срок от 2 до 6 месяцев на сумму не более 100 000 рублей.',
    loanAmount: 30000,
    minAmount: 5000,
    maxAmount: 100000,
    loanTerm: 6,
    timeTitle: TIME_TITLES.MONTHLY,
  },
  [PRODUCTS.OPTIMAL.key]: {
    name: PRODUCTS.OPTIMAL.key,
    title: PRODUCTS.OPTIMAL.title,
    description:
      'Оптимальный продукт отличается от Стандарта большей динамичностью - вы вносите платежи равными частями каждые две недели, уменьшая размер основного долга. Вы можете заключить договор на срок от 8 до 24 недель на сумму не более 100 000 рублей.',
    loanAmount: 30000,
    minAmount: 5000,
    maxAmount: 100000,
    loanTerm: 6,
    timeTitle: TIME_TITLES.WEEKLY,
  },
};

const ArrowHood = ({ className }: { className: string }) => (
  <svg
    className={`${styles.arrowHood} ${className}`}
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none"
  >
    <path
      d="M1.0625 7.14001L6.20264 2L11.3425 7.14001"
      stroke="#FDFDFD"
      strokeWidth="1.5"
      strokeMiterlimit="22.9256"
    />
  </svg>
);

const CloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
  >
    <path
      d="M9.2019 0.800049L0.808594 9.19328M9.2019 9.19328L0.808594 0.800049"
      stroke="#1C2A40"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="bevel"
    />
  </svg>
);

interface CustomStyles extends CSSProperties {
  '--display': 'none' | 'flex';
}

interface CustomStylesMargin extends CSSProperties {
  '--top': string;
}

const percentFromAmount = (amount: number) => {
  if (amount > 0 && amount <= AMOUNTS.THIRTY) {
    return BIDS.HIGHEST;
  }
  if (amount > AMOUNTS.THIRTY && amount <= AMOUNTS.FIFTY) {
    return BIDS.AVERAGE_SECOND;
  }
  if (amount > AMOUNTS.FIFTY && amount <= AMOUNTS.EIGHTY) {
    return BIDS.AVERAGE_FIRST;
  }

  return BIDS.SMALLEST;
};

const calculateMonthlyPayment = (
  loanAmount: number,
  months: number,
  withDevide?: boolean
) => {
  const monthlyRate = percentFromAmount(loanAmount) * 30;
  const monthlyPayment =
    (loanAmount * (monthlyRate / 100)) /
    // eslint-disable-next-line no-restricted-properties
    (1 - Math.pow(1 + monthlyRate / 100, -months));

  return (withDevide ? monthlyPayment / 2 : monthlyPayment).toFixed(2);
};

export const OurProducts = () => {
  const [currentProduct, setCurrentProduct] = useState(
    Object.keys(productsObj)[0]
  );
  const productData = productsObj[currentProduct];
  const { handleClickunderButtonClick } = useClickunder();
  const [isOpenProduct, setIsOpenProduct] = useState(false);
  const [month, setMonth] = useState(productData.loanTerm);
  const [amount, setAmount] = useState(productData.loanAmount);

  const downBlockRightInlineStyles: CustomStyles = {
    '--display': isOpenProduct ? `flex` : `none`,
  };

  const downBlockLeftInlineStyles: CustomStyles = {
    '--display': isOpenProduct ? `none` : `flex`,
  };

  const additionalDescriptionStyles: CustomStylesMargin = {
    '--top':
      currentProduct === PRODUCTS.LOAN_WITH_GRACE_PERIOD.key ? '-32px' : '0',
  };

  return (
    <div className={styles.container}>
      <div className={styles.upBlock}>
        <p className={styles.title}>Наши продукты</p>
        <div className={styles.leftButtonsBlock}>
          {Object.values(productsObj).map((el) => {
            const isActive = el.name === currentProduct;

            return (
              <Button
                className={styles.button}
                status={
                  isActive ? BUTTON_STATUSES.DEFAULT : BUTTON_STATUSES.STANDARD
                }
                onClick={() => {
                  setCurrentProduct(el.name);
                  setIsOpenProduct(false);
                  setMonth(el.loanTerm);
                  setAmount(el.loanAmount);
                }}
              >
                {el.title}
              </Button>
            );
          })}
        </div>
      </div>
      <div className={styles.downBlock}>
        <div style={downBlockLeftInlineStyles} className={styles.downBlockLeft}>
          <TitleDectRangeBlock
            title="Сумма займа"
            description={`${amount} ₽`}
            value={amount}
            minValue={productData.minAmount}
            minTitle={`${productData.minAmount} ₽`}
            maxValue={productData.maxAmount}
            step={1000}
            maxTitle={`${productData.maxAmount} ₽`}
            onChange={(value) => setAmount(+value)}
          />
          {productData.loanTerm > 1 && (
            <TitleDectRangeBlock
              title="Срок займа"
              description={`${month} ${
                productData.loanTerm === 1 ? 'месяц' : 'месяцев'
              }`}
              value={month}
              step={1}
              minValue={2}
              minTitle="2 месяца"
              maxValue={6}
              maxTitle="6 месяцев"
              onChange={(value) => setMonth(+value)}
            />
          )}
          <div className={styles.titleDescription}>
            <p className={styles.grayTitle}>{productData.timeTitle}</p>
            <p className={styles.amountDescription}>
              {calculateMonthlyPayment(
                amount,
                month,
                productData.timeTitle === TIME_TITLES.WEEKLY
              )}{' '}
              ₽
            </p>
          </div>
          {productData?.additionalDescription && (
            <p
              style={additionalDescriptionStyles}
              className={styles.additionalDescription}
            >
              {productData.additionalDescription}
            </p>
          )}
          <div className={styles.moreAboutProductBlock}>
            <p className={styles.moreAboutProductTitle}>
              Узнать больше о данном продукте
            </p>
            <div
              className={styles.moreAboutProductButton}
              onClick={() => setIsOpenProduct(true)}
            >
              ?
            </div>
          </div>
          <Button
            className={styles.getPackageButton}
            status={BUTTON_STATUSES.NEW_PINK}
            onClick={handleClickunderButtonClick}
          >
            Оформить
          </Button>
        </div>
        <div
          style={downBlockRightInlineStyles}
          className={styles.downBlockRight}
        >
          <div className={styles.downBlockRightTitleContainer}>
            <div className={styles.downBlockRightTitleBlock}>
              <div className={styles.downBlockRightTitleProd}>Продукт</div>
              <p className={styles.downBlockRightTitle}>{productData.title}</p>
            </div>
            <div
              className={styles.downBlockRightButton}
              onClick={() => setIsOpenProduct(false)}
            >
              <CloseIcon />
            </div>
          </div>
          <div className={styles.scheduleBlock}>
            <div className={styles.verticalArrow}>
              <ArrowHood className={styles.arrowHoodUp} />
            </div>
            <div className={styles.horizontalArrow}>
              <ArrowHood className={styles.arrowHoodRight} />
            </div>
            <div className={styles.scheduleColumnsBlock}>
              {new Array(currentProduct === PRODUCTS.OPTIMAL.key ? month * 2 : month).fill(null).map((_, loanTermIndex) => (
                <div
                  // eslint-disable-next-line react/no-array-index-key
                  key={loanTermIndex}
                  className={styles.scheduleColumnBlock}
                />
              ))}
            </div>
          </div>
          <p className={styles.scheduleDescription}>
            {productData.description}
          </p>
          <Button
            className={styles.getPackageButtonMobile}
            status={BUTTON_STATUSES.NEW_PINK}
            onClick={handleClickunderButtonClick}
          >
            Оформить
          </Button>
        </div>
      </div>
    </div>
  );
};
