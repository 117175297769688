import React, {useEffect} from 'react';
import { cn } from '@bem-react/classname';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PopupProvider } from '@src/components/Popup';
import { openLeftMenu, closeLeftMenu } from '@src/store/ducks/layout';
import {
  getContracts,
  getAccountStatus,
  getCreditLine,
  getUserFlags,
  refreshProfileState,
} from '@src/store/ducks/profile';
import {refreshToken} from '@src/store/ducks/auth';
import { ACCOUNT_MODERATION_STATUSES } from '@src/constants';

import './ProfileLayout.scss';

import Header from './components/Header/index';
import LeftMenu from './components/LeftMenu'
import HelpOverlay from './components/HelpOverlay'
import Subnav from '../MainLayout/components/Subnav'

export const componentClassName = cn('Layout-Profile');

export const ProfileLayout = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLeftMenuOpened = useSelector(({ layout }) => layout.isLeftMenuOpened);
  const isMenuLocked = useSelector(({ layout }) => layout.isNavigationLocked);
  const { isAuthenticated} = useSelector(({ auth }) => ({
    isAuthenticated: auth.isAuthenticated,
  }));

  const contracts = useSelector(({ profile }) => profile.contracts);
  const accountModerationStatus = useSelector(
    ({ profile }) => profile.accountModerationStatus
  );

  useEffect(() => {
    if (isAuthenticated && (accountModerationStatus === ACCOUNT_MODERATION_STATUSES.REGISTERED
        || accountModerationStatus === ACCOUNT_MODERATION_STATUSES.UNCOMPLETED_REGISTRATION)) {
      navigate('/sign_up')
    }
  }, [accountModerationStatus, isAuthenticated, navigate]);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const moveHandler = (e) => {
      if (e.clientX < 140 && !isLeftMenuOpened && !isMenuLocked) {
        dispatch(openLeftMenu());
      } else if (e.clientX >= 140 && isLeftMenuOpened) {
        dispatch(closeLeftMenu());
      }
    };

    window.addEventListener('mousemove', moveHandler);

    return () => window.removeEventListener('mousemove', moveHandler);
  }, [isLeftMenuOpened, isMenuLocked]);

  useEffect(() => {
    dispatch(refreshProfileState());

    const update = () => {
      dispatch(getUserFlags());
      dispatch(getContracts());
      dispatch(getCreditLine());
      dispatch(getAccountStatus());
    };
    update();
    const interval = setInterval(() => {
      dispatch(refreshToken());
      update()
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const url = new URL(window.location);
    if (
      url.searchParams.get('status') === 'declined' &&
      localStorage.getItem('direction') === 'OUTGOING'
    ) {
      return;
    }
    if (
      url.searchParams.get('status') === 'declined' &&
      localStorage.getItem('direction') === 'INCOMING'
    ) {
      return;
    }
    if (
      url.searchParams.get('status') === 'approved' &&
      localStorage.getItem('direction') === 'OUTGOING'
    ) {
      return;
    }
    if (
      url.searchParams.get('status') === 'approved' &&
      localStorage.getItem('direction') === 'INCOMING'
    ) {
      return;
    }
    if ( url.searchParams.get('step')) {
      return;
    }
    if (accountModerationStatus === ACCOUNT_MODERATION_STATUSES.REGISTERED) {
      navigate('/sign_up', { replace: true });
    } else {
      navigate('/profile/account-status', { replace: true });
    }
  }, [accountModerationStatus]);

  const widthWindow = () => window.innerWidth <= 1080;

  return (
    <PopupProvider>
      <HelpOverlay>
        {({ isShown, currentScreen }) => (
          <>
            <Header/>
            <div
              className={componentClassName('Central-Block', {
                isLeftMenuOpened,
              })}
            >
              <LeftMenu
                isFullView={isLeftMenuOpened}
                accountModerationStatus={accountModerationStatus}
                contracts={contracts}
                currentScreen={currentScreen}
              />
              <main className={componentClassName('Content-Block')}>
                {!isShown && <Outlet />}
              </main>
            </div>
            {!widthWindow() && <Subnav />}
          </>
        )}
      </HelpOverlay>
    </PopupProvider>
  );
};

export const getLayout = (page) => <ProfileLayout>{page}</ProfileLayout>;

export default ProfileLayout;
