import InputRange, {Range} from 'react-input-range';
import React from 'react';

import 'react-input-range/lib/css/index.css';
import styles from './LandingRangeForCreditLine.module.scss';

interface IProps {
    value: number;
    minValue: number;
    minTitle: string;
    maxValue: number;
    maxTitle: string;
    onChange: (value: number | Range) => void;
}

export const LandingRangeForCreditLine = ({minValue, minTitle, maxValue, maxTitle, onChange, value}: IProps) => (
  <div className={styles.container}>
    <InputRange
      minValue={minValue}
      maxValue={maxValue}
      value={value}
      step={1000}
      onChange={onChange}
            // @ts-ignore
      classNames={{
                track: styles.customTrack,
                activeTrack: styles.customActiveTrack,
                slider: styles.customSlider,
                valueLabel: styles.valueLabel,
                  minLabel: styles.customLabel,
                  maxLabel: styles.customLabel,
            }}
      formatLabel={(value) => `${value} ₽`}
        />
    <div className={styles.rangeLabels}>
      <p className={styles.rangeLabel}>{minTitle}</p>
      <p className={styles.rangeLabel}>{maxTitle}</p>
    </div>
  </div>
);
