import React, {useCallback} from 'react';
import {LoanLimitProgressBar} from '@src/layouts/ProfileLayout/components/LoanLimitProgressBar/LoanLimitProgressBar';
import BdButton from '@components/BdButton';
import {logout} from '@src/store/ducks/auth';
import {useDispatch} from 'react-redux';
import { ReactComponent as HeaderLogo } from '@images/Profile/HeaerLogo.svg';

import styles from './Header.module.scss';

const Header = () => {
  const dispatch = useDispatch();

  const handleLogout = useCallback(async () => {
    dispatch(
        logout()
    );
  }, []);

  return (
    <div className={styles.header}>
      <HeaderLogo />
      <LoanLimitProgressBar />
      <BdButton onClick={handleLogout} className={styles.logoutButton}>Выйти</BdButton>
    </div>
  );
};

export default Header;
