import React, { useContext, useState } from 'react';
import Button, { BUTTON_STATUSES } from '@src/components/Button';
import CloseIcon from '@src/assets/images/Subnav/Close.svg';
import PlusIcon from '@src/assets/images/Subnav/Plus.svg';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';

import {Context} from '../../context';
import './Menu.scss'
import {useSelector} from "react-redux";
import {ACCOUNT_MODERATION_STATUSES} from "@src/constants";

const Menu = () => {

  const navigate = useNavigate();

  const {hideMenu} = useContext(Context)
  const [productState, setProductState] = useState(false)
  const [actionsState, setActionsState] = useState(false)

  const toSignIn = () => {
    navigate('/login')
  }

  // @ts-ignore
  const { isAuthenticated, accountModerationStatus } = useSelector(({ auth, profile }) => ({
    isAuthenticated: auth.isAuthenticated,
    accountModerationStatus: profile.accountModerationStatus,
  }));

  const clickHandler = () => {
    if (isAuthenticated && accountModerationStatus === ACCOUNT_MODERATION_STATUSES.REGISTERED) {
      navigate('/sign_up');
    } else if (isAuthenticated) {
      navigate('/profile/add-contract');
    } else {
      window.location.href = '/sign_up/phone';
    }
  }

  return (
    <div className="mobile-menu">
      <header className='mobile-menu__header'>
        <Button
          onClick={()=> hideMenu()}
          status={BUTTON_STATUSES.ACTIVED}
        >
          <div onClick={toSignIn} className='text'>Личный кабинет</div>
        </Button>
        <Button
          onClick={() => hideMenu()}
        >
          <div className='icon'>
            <img src={CloseIcon} alt='Menu' />
          </div>
        </Button>
      </header>
      <ul className="mobile-menu__tags">
        <li className='mobile-menu__item'>
          <a className="mobile-menu__link" onClick={() => hideMenu()} href='/#conditions'>Условия</a>
        </li>

        <li className='mobile-menu__item'>
          <a className="mobile-menu__link" href='/#creditline' onClick={()=> hideMenu()}>Кредитная линия</a>
        </li>

        <li className='mobile-menu__item'>
          <Button onClick={()=> {
            setActionsState(false);
            setProductState(!productState);
          }}>
            <div className='mobile-menu__text'>Продукты</div>
            <div className={classnames('icon', {'mobile-menu__icon_show': productState})}><img src={PlusIcon} alt='PlusIcon' /></div>
          </Button>
          <ul className={classnames('mobile-menu__sub-tags', {'mobile-menu__sub-tags_show': productState})}>
            <li className='mobile-menu__sub-item'>
              <p onClick={clickHandler}>Стандарт</p>
            </li>
            <li className='mobile-menu__sub-item' >
              <p onClick={clickHandler}>До зарплаты</p>
            </li>
          </ul>
        </li>

        <li className='mobile-menu__item'>
          <Button onClick={()=> {
            setProductState(false);
            setActionsState(!actionsState);
          }}>
            <div className='mobile-menu__text'>Действия</div>
            <div className={classnames('icon', {'mobile-menu__icon_show': actionsState})}><img src={PlusIcon} alt='PlusIcon' /></div>
          </Button>
          <ul className={classnames('mobile-menu__sub-tags', {'mobile-menu__sub-tags_show': actionsState})}>
            <li className='mobile-menu__sub-item'>
              <a href='/#gettingfunds' onClick={()=> hideMenu()}>Получение и возврат</a>
            </li>
            <li className='mobile-menu__sub-item' >
              <a href='/#stepssignup' onClick={()=> hideMenu()}>Этапы регистрации</a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default Menu;
