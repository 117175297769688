import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import Collapsible from 'react-collapsible';
import { useLocation } from 'react-router-dom';
import { ReactComponent as PlusIcon } from '@src/assets/images/PlusIcon.svg';
import './SupportMobile.scss';
import {supportQuestionsAnswers} from '@src/constants';

const CollapseItem = ({ title, description, id, open, toggleHandler }) => (
  <Collapsible
    key={id}
    open={open}
    classParentString="page-support__question"
    triggerClassName={classnames('page-support__question__header')}
    triggerOpenedClassName={classnames('page-support__question__header')}
    triggerTagName="div"
    onTriggerOpening={() => toggleHandler(id)}
    onTriggerClosing={() => toggleHandler(id)}
    trigger={
      <>
        <div className={classnames('page-support__question__title')}>
          {title}
        </div>
        <div
          className={classnames('page-support__question__close', {
            'page-support__question__close_is-open': open,
          })}
        >
          <PlusIcon />
        </div>
      </>
    }
  >
    <div
      className={classnames(
        'page-support__question__content',
        'page-support__question__content_mobile'
      )}
    >
      <div className={classnames('page-support__question__content__slider')}>
        {description.split('\n').map((str, index) => (
          <p key={index}>{str}</p>
        ))}
      </div>
    </div>
  </Collapsible>
);

const SupportMobile = () => {
  const location = useLocation();
  const [activeId, setActiveId] = useState(1);
  const [hideSupportMobile, setHideSupportMobile] = useState(false);
  const toggleHandler = (id) => {
    setActiveId(id !== activeId ? id : null);
  };
  useEffect(() => {
    switch (location.hash) {
      case `#stepsupport`:
        setHideSupportMobile(true);
        break;
      default:
        break;
    }
  }, [location.hash]);

  return (
    <div>
      <Collapsible
        triggerTagName="div"
        triggerClassName="page-support__title-mobile"
        triggerOpenedClassName={classnames(
          'page-support__title-mobile',
          'page-support__title-mobile_opened'
        )}
        open={hideSupportMobile}
        onClick={() => setHideSupportMobile(!hideSupportMobile)}
        trigger={
          <>
            <h2>Часто задаваемые вопросы</h2>
            <svg
              className="page-support__title-mobile__icon"
              xmlns="http://www.w3.org/2000/svg"
              width="27"
              height="19"
              viewBox="0 0 27 19"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.578711 4.24576C0.368133 3.98338 0.208918 3.68044 0.111589 3.3541C0.0142589 3.02775 -0.0198511 2.68459 0.0110276 2.34444C0.0419063 2.00429 0.137285 1.67354 0.291625 1.37187C0.445966 1.0702 0.65551 0.803224 0.90959 0.5864C1.42303 0.153162 2.0807 -0.0529313 2.73915 0.0116228C3.3976 0.0761769 4.00443 0.40656 4.43085 0.931941L13.4992 12.2547L22.5675 0.931941C22.9939 0.40656 23.6024 0.0761769 24.2608 0.0116228C24.9193 -0.0529313 25.5753 0.153162 26.0888 0.5864C26.3429 0.803224 26.554 1.0702 26.7084 1.37187C26.8627 1.67354 26.9581 2.00429 26.989 2.34444C27.0199 2.68459 26.9857 3.02775 26.8884 3.3541C26.7911 3.68044 26.6319 3.98338 26.4213 4.24576L15.4601 18.0539L15.1276 18.3994C14.8752 18.6183 14.5836 18.7829 14.2696 18.884C13.9556 18.9852 13.6254 19.0206 13.2981 18.9885C12.9708 18.9565 12.6525 18.8573 12.3622 18.6969C12.0719 18.5365 11.815 18.3179 11.6064 18.0539L0.578711 4.24576Z"
                fill="#1C2A40"
              />
            </svg>
          </>
        }
      >
        <div className="page-support__question__content__slider page-support__question__content__slider_intro">
          Ответы на наиболее часто задаваемые вопросы, которые позволят узнать
          чуть больше о работе сервиса и условиях договора.
        </div>
        {supportQuestionsAnswers.map((t, id) =>
          CollapseItem({ ...t, id, open: activeId === id, toggleHandler })
        )}
      </Collapsible>
    </div>
  );
};

export default SupportMobile;
