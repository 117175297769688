import React, { useState, useRef, useEffect } from 'react';
import classnames from 'classnames';
import { getLayout } from '@src/layouts/ProfileLayout';
import {
  supportQuestionsAnswers,
  TELEGRAM_LINK,
  WHATSAPP_LINK,
  WHATSAPP_LINK_MOBILE,
} from '@src/constants';
import { ReactComponent as PlusIcon } from '@src/assets/images/PlusIcon.svg';
import { ReactComponent as PhoneIcon } from '@src/assets/images/phone.svg';
import TelegramIcon from '@src/assets/images/TelegramIcon.svg';
import EmailIcon from '@src/assets/images/EmailIconDark.svg';
import { useLocation } from 'react-router-dom';
import Collapsible from 'react-collapsible';
import WhatsappIcon from '@src/assets/images/WhatsappIcon.svg';
import 'moment/locale/ru';
import './Support.scss';

import Button from '@src/components/Button';
import {LoanLimitProgressBar} from '@src/layouts/ProfileLayout/components/LoanLimitProgressBar/LoanLimitProgressBar';

const openSite = () => {
  /* global jivo_api */
  jivo_api.open();
};

const Question = ({ title, description, closeAll, setCloseAll }) => {
  const ref = useRef(null);
  const [height, setHeight] = useState(0);
  const [isOpen, setOpen] = useState(0);

  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.clientHeight);
    }
  }, [ref]);

  useEffect(() => {
    if (closeAll) {
      setOpen(false);
    }
  }, [closeAll]);

  return (
    <div
      className={classnames('page-support__question', {
        'page-support__question_is-open': isOpen,
      })}
    >
      <div className={classnames('page-support__question__header')}>
        <div className={classnames('page-support__question__title')}>
          {title}
        </div>
        <div
          className={classnames('page-support__question__close', {
            'page-support__question__close_is-open': isOpen,
          })}
          onClick={() => {
            setCloseAll(true);
            setTimeout(() => {
              setOpen(!isOpen);
            }, 10);
          }}
        >
          <PlusIcon />
        </div>
      </div>
      <div
        className={classnames('page-support__question__content', {
          'page-support__question__content_is-open': isOpen,
        })}
        style={{ height: isOpen ? height : 0 }}
      >
        <div
          ref={ref}
          className={classnames('page-support__question__content__slider')}
        >
          {description}
        </div>
      </div>
    </div>
  );
};

export const SupportQuestions = () => {
  const [closeAll, setCloseAll] = useState(true);

  useEffect(() => {
    if (closeAll) {
      setCloseAll(false);
    }
  }, [closeAll]);

  const questionSeparatorIndex = supportQuestionsAnswers.length / 2;

  return (
    <div className={classnames('page-support__content')}>
      <div className={classnames('page-support__content__left')}>
        {supportQuestionsAnswers.slice(0, questionSeparatorIndex).map((item) => (
          <Question
            key={item.title}
            title={item.title}
            description={item.description}
            closeAll={closeAll}
            setCloseAll={setCloseAll}
          />
        ))}
      </div>
      <div className={classnames('page-support__content__right')}>
        {supportQuestionsAnswers.slice(questionSeparatorIndex, supportQuestionsAnswers.length).map((item) => (
          <Question
            key={item.title}
            title={item.title}
            description={item.description}
            closeAll={closeAll}
            setCloseAll={setCloseAll}
            />
        ))}
      </div>
    </div>
  );
};

const Support = () => {
  const location = useLocation();
  const [hideSignUpStepsMobile, setHideSignUpStepsMobile] = useState(false);

  useEffect(() => {
    switch (location.hash) {
      case `#stepssignup`:
        setHideSignUpStepsMobile(true);
        break;
      default:
        break;
    }
  }, [location.hash]);

  return (
    <div className={classnames('page-support')}>
      <div className="page-support__desktop">
        <div className={classnames('page-support__header')}>
          <h2 className={classnames('page-support__title')}>
            Часто задаваемые вопросы
          </h2>
        </div>
        <SupportQuestions />
      </div>
      <div>
        <LoanLimitProgressBar className={classnames('page-support__headerLimit')} />
        <Collapsible
          classParentString="page-support-mobile"
          contentOuterClassName="page-support-mobile__content-outer"
          triggerTagName="div"
          triggerClassName="page-support-mobile__title-mobile"
          triggerOpenedClassName={classnames(
                'page-support-mobile__title-mobile',
                'page-support-mobile__title-mobile_opened'
            )}
          open={false}
          onOpening={() => setHideSignUpStepsMobile(true)}
          onClose={() => setHideSignUpStepsMobile(false)}
          trigger={
            <>
              <div className="page-support-mobile__title-container">
                <h2>Часто задаваемые вопросы</h2>
                <svg
                  className="page-support__title-mobile__icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="27"
                  height="19"
                  viewBox="0 0 27 19"
                  fill="none"
                  >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.578711 4.24576C0.368133 3.98338 0.208918 3.68044 0.111589 3.3541C0.0142589 3.02775 -0.0198511 2.68459 0.0110276 2.34444C0.0419063 2.00429 0.137285 1.67354 0.291625 1.37187C0.445966 1.0702 0.65551 0.803224 0.90959 0.5864C1.42303 0.153162 2.0807 -0.0529313 2.73915 0.0116228C3.3976 0.0761769 4.00443 0.40656 4.43085 0.931941L13.4992 12.2547L22.5675 0.931941C22.9939 0.40656 23.6024 0.0761769 24.2608 0.0116228C24.9193 -0.0529313 25.5753 0.153162 26.0888 0.5864C26.3429 0.803224 26.554 1.0702 26.7084 1.37187C26.8627 1.67354 26.9581 2.00429 26.989 2.34444C27.0199 2.68459 26.9857 3.02775 26.8884 3.3541C26.7911 3.68044 26.6319 3.98338 26.4213 4.24576L15.4601 18.0539L15.1276 18.3994C14.8752 18.6183 14.5836 18.7829 14.2696 18.884C13.9556 18.9852 13.6254 19.0206 13.2981 18.9885C12.9708 18.9565 12.6525 18.8573 12.3622 18.6969C12.0719 18.5365 11.815 18.3179 11.6064 18.0539L0.578711 4.24576Z"
                    fill="#1C2A40"
                    />
                </svg>
              </div>
              <p style={hideSignUpStepsMobile ? {} : {borderBottom: '1px solid #AFC3D5'}} className="page-support-mobile__title-description">
                Ответы на часто задаваемые вопросы, которые позволят узнать чуть больше о работе сервиса и условиях договора.
              </p>
            </>
            }
        >
          <SupportQuestions />
        </Collapsible>
      </div>
      <div className={classnames('page-support__footer')}>
        <p className={classnames('page-support__footer__description')}>
          Если Вам нужна помощь, и Вы не нашли ответа выше - обратитесь в службу поддержки по телефону или в оперативном онлайн-чате.
        </p>
        <div className="page-support__block">
          <div className="page-support__Jivo-Block" onClick={openSite}>
            {window.innerWidth > 850 ? 'Онлайн чат' : 'Онлайн'}
          </div>
          <a
            href={TELEGRAM_LINK}
            className="page-support__social-link"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="page-support__social-link__img"
              src={TelegramIcon}
              alt="Telegram"
            />
          </a>
          <a
            href={window.innerWidth > 850 ? WHATSAPP_LINK : WHATSAPP_LINK_MOBILE}
            className="page-support__social-link"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="page-support__social-link__img"
              src={WhatsappIcon}
              alt="Telegram"
            />
          </a>
          <a
            href='mailto:support@budgett.ru'
            className="page-support__social-link_square"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="page-support__social-link__img"
              src={EmailIcon}
              alt="email"
            />
          </a>
          <a className="page-support__link" href="tel:88005559980">
            <Button>
              <PhoneIcon/>
              <span>8 (800) 555-99-80</span>
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
};

Support.getLayout = getLayout;

export default Support;
