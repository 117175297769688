import React from 'react';
import { Button, ConfigProvider } from 'antd';
import './index.scss';
import { ButtonProps } from 'antd/es/button/button';

interface buttonTypes extends ButtonProps {
  status?: 'pink';
}

const BdButton: React.FC<buttonTypes> = ({
  children,
  status,
  ...restProps
}) => {
  let colorPrimary = '#1C2A40';
  let colorPrimaryHover = 'rgba(28,42,64,0.75)';
  const colorBgContainerDisabled = '#AFC3D5';
  const colorTextDisabled = '#FEFEFE';

  if (status === 'pink') {
    colorPrimary = '#F76083';
    colorPrimaryHover = 'rgba(247,96,131,0.75)';
  }

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimary,
            colorPrimaryHover,
            colorPrimaryActive: colorPrimary,
            borderRadius: 50,
            colorBgContainerDisabled,
            colorTextDisabled,
          },
        },
      }}
    >
      <Button type="primary" {...restProps}>
        {children}
      </Button>
    </ConfigProvider>
  );
};

export default BdButton;
