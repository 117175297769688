import React from 'react';
import { cn } from '@bem-react/classname';
import Loading from '@src/components/Loading';
import './Button.scss';

export const componentClassName = cn('Button');

export const BUTTON_STATUSES = {
  DEFAULT: 'default',
  DISABLED: 'disabled',
  SMOOTHED: 'smoothed',
  ACTIVED: 'actived',
  COMPLETED: 'completed',
  NEW: 'new',
  NEW_PINK: 'new-pink',
  HEADER: 'header',
  STANDARD: 'standard',
};

const waitingStyle = {
  backgroundColor: '#1C2A40',
};

const Button = ({
  heightFull = false,
  onClick,
  onMouseEnter = null,
  onMouseLeave = null,
  isWaiting = false,
  disabled = false,
  children,
  isLoading = false,
  status = BUTTON_STATUSES.DEFAULT,
  className,
  ...restProps
}) => (
  <button
    type="button"
    className={`${componentClassName({ status, clicked: !!onClick, heightFull})} ${className}`}
    onClick={onClick || (() => {})}
    onMouseEnter={onMouseEnter || (() => {})}
    onMouseLeave={onMouseLeave || (() => {})}
    disabled={disabled}
    style={isWaiting ? waitingStyle : null}
    {...restProps}
  >
    {isLoading && (
      <div className={componentClassName('Loading', { isLoading })}>
        <Loading />
      </div>
    )}
    <div className={componentClassName('Content', { isLoading })}>
      {children}
    </div>
  </button>
);

export default Button;
