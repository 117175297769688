import React, {useEffect, useState, useContext, useCallback} from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { cn } from '@bem-react/classname';
import classnames from 'classnames';
import lodash from 'lodash';
import { ACCOUNT_MODERATION_STATUSES } from '@src/constants';
import DocsIcon from '@src/assets/images/ProfileLeftMenu/DocsIcon.svg';
import DeleteAccountIcon from '@src/assets/images/ProfileLeftMenu/DeleteAccount.svg';
import AddContractIcon from '@src/components/Icons/AddContractIcon/AddContractIcon';
import MenuProfileIcon from '@src/components/Icons/MenuProfileIcon/MenuProfileIcon';
import MenuSupportIcon from '@src/components/Icons/MenuSupportIcon/MenuSupportIcon';
import PaymentIcon from '@src/components/Icons/PaymentIcon/PaymentIcon';
import LockIcon from '@src/assets/images/ProfileLeftMenu/LockIcon.svg';
import LogoutIcon from '@src/assets/images/ProfileLeftMenu/DeleteAccountIcon.svg';
import { ReactComponent as HelpArrowLeftThird } from '@src/assets/images/HelpArrowLeftThird.svg';

import './LeftMenu.scss';
import {useDispatch, useSelector} from 'react-redux';

import {logout} from '@src/store/ducks/auth';

import { getUserFlags } from '../../../../api';
import { HelpOverlayContext } from '../HelpOverlay';
import Header from '../Header';

const widthWindow = () => window.innerWidth <= 1080;

const profileLinks = [
  {
    id: 'password_change',
    label: 'Сменить пароль',
    icon: LockIcon,
    to: '/profile/settings/password-change'
  },
  {
    id: 'documents',
    label: 'Документы',
    icon: DocsIcon,
    to: '/profile/settings/documents'
  },
  {
    id: 'delete_account',
    label: 'Удалить аккаунт',
    icon: DeleteAccountIcon,
    to: '/profile/settings/delete-account'
  },
  {
    id: 'logout',
    label: 'Выйти из личного кабинета',
    icon: LogoutIcon,
  },
];

export const componentClassName = cn('Component-Profile-Layout-Left-Menu');

const isActiveLink = (location, link) => link && location.pathname === link;

const Link = ({ id, label, to, icon, help, disabled, forceActive, closeMenu }) => {
  const location = useLocation();
  const isActive = forceActive || isActiveLink(location, to);

  return (
    <div className={componentClassName('Link', { isActive, id })}>
      <NavLink to={to} className={classnames({
        'LinkContainer': true,
        'Disabled': disabled,
      })} onClick={(e) => {
        if (closeMenu) {
          closeMenu()
        }

        if (disabled) {
          e.preventDefault()
        }
      }}>
        <div className="Icon">
          {icon}
        </div>
        <div className="Label">
          {label}
        </div>
      </NavLink>
      {!!help && help}
    </div>
  )
}

const LinkSub = ({ id, label, to, icon, help, disabled, forceActive, closeMenu }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isActive = forceActive || isActiveLink(location, to);

  const onHandleClickLink = (e) => {
    if (widthWindow()) {
      // eslint-disable-next-line no-unused-expressions
      closeMenu?.();
    }
    if (disabled) {
      e.preventDefault();
    }
  };

  const handleLogout = useCallback(async () => {
    dispatch(
        logout()
    );
  }, []);

  const onHandleClick = (e) => {
    if(id === 'logout') {
      handleLogout();
    }
    if (disabled) {
      e.preventDefault();
    }
  };

  const linkClassName = classnames({
    'LinkContainer': true,
    'Disabled': disabled,
  });

  const insideBlock = (
    <>
      <div className="Icon">
        <img src={icon} alt={label} />
      </div>
      <div className="Label">
        {label}
      </div>
    </>
  );

  return (
    <div className={componentClassName('Link', { isActive, id })}>
      {to
        ? (
          <NavLink
            to={to}
            className={linkClassName}
            onClick={onHandleClickLink}
          >
            {insideBlock}
          </NavLink>
        )
        : (
          <div className={linkClassName}
            onClick={onHandleClick}>
            {insideBlock}
          </div>
        )}
      {!!help && help}
    </div>
  )
}

const Tree = ({ id, label, icon, help, links, disabled, openedTree, onClick, closeMenu }) => {
  const location = useLocation();
  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    if (openedTree) {
      setIsOpened(id === openedTree);
    } else {
      setIsOpened(false);
    }
  }, [openedTree]);

  useEffect(() => {
    if (!openedTree) {
      const anyLinksIsActive = lodash.find(links, (link) => isActiveLink(location, link.to));
      if (window.innerWidth > 1080) {
        setIsOpened(!!anyLinksIsActive);
      }
    }
  }, [location]);

  const allCloseMenu = () => {
    if (closeMenu) {
      closeMenu()
    }
    setIsOpened(false)
  }

  return (
    <div className={componentClassName('Tree', { isOpened })}>
      <div className={classnames({
        'TreeHeaderContainer': true,
        'Disabled': disabled,
      })} onClick={() => !disabled && onClick(isOpened)}>
        <div className="Icon">
          {icon}
        </div>
        <div className="Label">
          {label}
        </div>
        {!disabled && <div className={classnames('Arrow', isOpened ? 'Arrow-Top' : 'Arrow-Bottom')} />}
      </div>
      {!!help && help}
      <div className={classnames({
        'Submenu': true,
        isOpened
      })}>
        <div className="submenu_container">
          <div className="submenu_title">
            {label}
          </div>
          {links.map(link => <LinkSub key={link.id} {...link} closeMenu={allCloseMenu}/>)}
        </div>
      </div>
    </div>
  )
}

const LeftMenu = ({ accountModerationStatus, isFullView, contracts, currentScreen }) => {
  const { helpScreen, SCREENS } = useContext(HelpOverlayContext);
  const isHelpFourthScreen = helpScreen === SCREENS.FOURTH;
  const isDemo1 =
    (accountModerationStatus === ACCOUNT_MODERATION_STATUSES.DATA_CHECKING && contracts.length === 0) ||
    accountModerationStatus === ACCOUNT_MODERATION_STATUSES.REGISTERED ||
    accountModerationStatus === ACCOUNT_MODERATION_STATUSES.FAILURE_LITTLE_DATA ||
    accountModerationStatus === ACCOUNT_MODERATION_STATUSES.DATA_MISMATCH;

  const isDemo2 =
    (accountModerationStatus === ACCOUNT_MODERATION_STATUSES.DATA_CHECKING && contracts.length !== 0) ||
    accountModerationStatus === ACCOUNT_MODERATION_STATUSES.LIMIT_REACHED;

  const [openedTree, setOpenedTree] = useState(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    (async () => {
      const { result } = await getUserFlags()
      window.ym(87515319, 'setUserID', result?.data?.clientId.toString());
    })()
  }, []);

  useEffect(() => {
    if (isHelpFourthScreen) {
      setOpenedTree('products');
    } else {
      setOpenedTree(null);
    }
  }, [isHelpFourthScreen])

  const closeMenu = () => {
    if (widthWindow()) {
      setTimeout(() => {
        setShow(false)
        setOpenedTree(null)
      }, 0)
    }
  }

  const today = new Date();
  const year = today.getFullYear();
  const isMenuLocked = useSelector(({ layout }) => layout.isNavigationLocked);

  const onHandleProfileClick = (isOpened) => {
    setOpenedTree((openedTree === 'profile' || isOpened) ? false : 'profile')
    setShow(!isOpened)
  };

  const linksArr = [
    {
      mobileOrder: 2,
      id: 'add_contract',
      label: 'Получить',
      icon: <AddContractIcon />,
      to: '/profile/add-contract',
      closeMenu,
      disabled: isMenuLocked || isDemo1 || isDemo2,
    },
    {
      mobileOrder: 3,
      id: 'my_payments',
      label: 'Оплатить',
      icon: <PaymentIcon />,
      to: '/profile/my-payments',
      closeMenu,
      disabled: isMenuLocked || !(contracts.length > 0)
    },
    {
      mobileOrder: 1,
      id: 'profile',
      label: 'Профиль',
      icon: <MenuProfileIcon />,
      disabled: isMenuLocked,

      openedTree,
      onClick: onHandleProfileClick,
      closeMenu,
      links: profileLinks,
    },
    {
      mobileOrder: 4,
      id: 'support',
      label: 'Поддержка',
      icon: <MenuSupportIcon />,
      to: '/profile/support',
      closeMenu,
      disabled: isMenuLocked,
    },
  ];

  return (
    <>
      <Header helpScreen={currentScreen} show={show} />
      <div className={componentClassName({ state: (isFullView || isHelpFourthScreen) ? 'open' : 'close', isHelpFourthScreen, isMenuLocked })}>

        {(widthWindow() ? linksArr.sort((a, b) => a.mobileOrder - b.mobileOrder) : linksArr).map((el, i) =>
            // eslint-disable-next-line react/no-array-index-key
            el.links ? <Tree key={i} {...el}/> : <Link key={i} {...el} />)}

        {isHelpFourthScreen && (
        <p className={componentClassName('HelpMessage', { Third: true })}>
          <HelpArrowLeftThird />
          Если возникли вопросы или трудности, наши операторы помогут вам.
        </p>
      )}
      </div>
      <div className={componentClassName('Copyright')}>ООО МКК «Диджитал Мани», {year}</div>
    </>
  )
};

export default LeftMenu;
