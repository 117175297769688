import {
  CODE_CHECK_STATUSES,
  CODE_EVENT_TYPE,
  SMS_SERVICE_API_HOST,
} from '@src/constants';
import { getCookie } from '@src/utils/cookies';
import axios from 'axios';

const smsService = axios.create({
  baseURL: SMS_SERVICE_API_HOST,
  headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/json',
  },
});

smsService.interceptors.request.use((config) => {
  const accessToken = getCookie('ACCESS_TOKEN');
  if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;

  return config;
});
smsService.interceptors.response.use((response) => response.data);

export interface PostSendRegistrationPhoneCodeParams {
  phone: string;
}

export async function postSendRegistrationPhoneCode({ phone }: PostSendRegistrationPhoneCodeParams): Promise<unknown> {
  const { csrf: csrfToken } = await getCsrfToken()

  return smsService.request({
    method: 'post',
    url: '/send/send_code/registration',
    headers: {
      'X-CSRF': csrfToken
    },
    data: {
      phone,
      eventCode: CODE_EVENT_TYPE.REGISTRATION,
    },
  });
}

interface GetCsrfToken {
  csrf: string;
}

export function getCsrfToken(): Promise<GetCsrfToken> {
  return smsService.request({
    method: 'get',
    url: '/csrf'
  });
}

export interface PostCheckCodeParams {
  phone: string;
  code: string;
}

export function postCheckPhoneCode(
  data: PostCheckCodeParams
): Promise<{ status: CODE_CHECK_STATUSES }> {
  return smsService.request({
    method: 'post',
    url: '/send/check_code',
    data,
  });
}

export function getUrl(urlId: string): Promise<{ url: string }> {
  return smsService.request({
    url: '/url',
    params: {
      urlId,
    },
  });
}
