import React from 'react';
import PropTypes from 'prop-types';

const PaymentIcon = ({width, height}) => (
  <svg width={width} height={height} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M14.2536 15.1029V12.8869C14.2536 11.9049 15.6627 11.8411 15.8327 12.6713C15.88 12.9038 15.8452 13.6796 15.8443 13.9657C15.8421 14.7164 15.7653 14.5459 16.1711 14.9476C16.3893 15.1632 16.6964 15.3859 16.6964 15.7858C16.6964 16.2589 16.3572 16.6379 15.901 16.6379C15.4288 16.6379 15.1762 16.2808 14.9191 16.0273C14.7022 15.8104 14.2496 15.4787 14.2496 15.1043L14.2536 15.1029ZM10.0491 14.7052C10.0491 16.2839 10.399 17.4127 11.5122 18.5263C11.9753 18.9933 12.5262 19.364 13.1333 19.6169C13.7404 19.8699 14.3915 20.0001 15.0492 20.0001C15.7068 20.0001 16.358 19.8699 16.9651 19.6169C17.5721 19.364 18.1231 18.9933 18.5862 18.5263C19.0539 18.0581 19.2633 17.7568 19.5766 17.1302C20.2224 15.8358 20.2175 14.1201 19.5574 12.811C18.7759 11.2604 17.0838 9.98926 15.3904 9.98926C14.3875 9.98926 13.8282 10.0018 12.9088 10.4619C11.3131 11.2595 10.0469 12.9208 10.0469 14.7052H10.0491Z" fill="#7A8090"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M2.97656 2.18719H13.1977C14.2077 2.18719 15.7284 2.08453 16.5586 2.27645C16.547 1.75692 16.2015 1.07848 15.8444 0.721416L15.5423 0.478165C14.546 -0.273465 13.623 0.0318311 12.5371 0.251428L11.1749 0.523692C10.2443 0.709814 9.3672 0.876298 8.44195 1.0593L3.32381 2.07292C3.26027 2.0825 3.19725 2.09532 3.13501 2.11131C3.12028 2.11577 3.08011 2.12961 3.06761 2.13496C3.03412 2.14619 3.00317 2.16394 2.97656 2.18719Z" fill="#7A8090"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M0 5.53209L0.0687348 17.2658C0.0687348 18.2924 0.80876 19.1199 1.67554 19.4729C2.30934 19.7305 4.54549 19.6278 5.38013 19.6278H11.3213C10.6282 19.0709 10.0691 18.3654 9.68526 17.5634C9.30139 16.7615 9.10256 15.8835 9.10347 14.9944C9.10347 13.4166 9.73026 11.9034 10.846 10.7877C11.9616 9.67199 13.4749 9.0452 15.0527 9.0452C16.2087 9.04345 17.34 9.38015 18.3069 10.0138C18.2864 8.78945 18.2556 7.60354 18.2556 6.30426C18.2556 5.34419 18.3056 4.776 17.6517 4.00116C17.1652 3.42405 16.3872 3.125 15.5758 3.125H2.76594C1.19663 3.125 0 4.14309 0 5.53209Z" fill="#7A8090"/>
  </svg>

);

PaymentIcon.defaultProps = {
    width: 19,
    height: 20,
};

PaymentIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
};

export default React.memo(PaymentIcon);
