import React from 'react';

import Button, { BUTTON_STATUSES } from '@components/Button';

import handShakeImg from '@images/goodService/handShake.png';
import catImg from '@images/goodService/cat.png';
import listImg from '@images/goodService/list.png';
import calendarImg from '@images/goodService/calendar.png';
import umbrellaImg from '@images/goodService/umbrella.png';
import phoneImg from '@images/goodService/phone.png';
import ballsImg from '@images/goodService/balls.png';
import scheduleImg from '@images/goodService/schedule.png';

import { useClickunder } from '@src/pages/LandingPage/hooks/useClickunder';

import styles from './goodService.module.scss';

const goodServiceArr = [
  {
    title: 'Нет скрытой комиссии при возврате',
    image: handShakeImg,
    imageBottom: 35,
    imageBottomMobile: 0,
    imageMobileWidth: 128,
  },
  {
    title: 'Не звоним родственникам и работодателю',
    image: catImg,
    imageBottom: 20,
    imageBottomMobile: 0,
    imageMobileWidth: 128,
  },
  {
    title: 'Легальная организация с лицензией ЦБ',
    image: listImg,
    imageBottom: 38,
    imageBottomMobile: 10,
    imageMobileWidth: 121,
  },
  {
    title: 'Пролонгация до четырех раз',
    image: calendarImg,
    imageBottom: -24,
    imageBottomMobile: -15,
    imageMobileWidth: 163,
  },
  {
    title: 'Без платных страховок и телемедицины',
    image: umbrellaImg,
    imageBottom: 38,
    imageBottomMobile: 0,
    imageMobileWidth: 117,
  },
  {
    title: 'Займы до 300 000 руб. на срок до 36 мес.',
    image: phoneImg,
    imageBottom: 31,
    imageBottomMobile: -6,
    imageMobileWidth: 121,
  },
  {
    title: 'Более одного займа в рамках кредитной линии',
    image: ballsImg,
    imageBottom: 8,
    imageBottomMobile: 0,
    imageMobileWidth: 128,
  },
  {
    title: 'Услуги рефинансирования займов',
    image: scheduleImg,
    imageBottom: 31,
    imageBottomMobile: 11,
    imageMobileWidth: 128,
  },
];

export const GoodService = () => {
  const { handleClickunderButtonClick } = useClickunder();

  return (
    <div className={styles.container}>
      <div className={styles.upBlock}>
        <p className={styles.title}>Удобный сервис</p>
        <Button
          className={styles.button}
          status={BUTTON_STATUSES.NEW_PINK}
          onClick={handleClickunderButtonClick}
        >
          Получить деньги
        </Button>
      </div>
      <div className={styles.downBlockDeskTop}>
        {goodServiceArr.map((el, goodIndex) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className={styles.item} key={goodIndex}>
            <p className={styles.itemTitle}>{el.title}</p>
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <img
              className={styles.itemImage}
              style={{ bottom: el.imageBottom }}
              src={el.image}
              alt={el.title}
            />
          </div>
        ))}
      </div>
      <div className={styles.downBlockMobile}>
        {goodServiceArr.slice(0, 4).map((el, goodIndex) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className={styles.item} key={goodIndex}>
            <p className={styles.itemTitle}>{el.title}</p>
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <img
              className={styles.itemImage}
              style={{
                bottom: el.imageBottomMobile,
                width: el.imageMobileWidth,
              }}
              src={el.image}
              alt={el.title}
            />
          </div>
        ))}
      </div>
      <Button
        className={styles.buttonMobile}
        status={BUTTON_STATUSES.NEW_PINK}
        onClick={handleClickunderButtonClick}
      >
        Получить деньги
      </Button>
      <div className={styles.downBlockMobile}>
        {goodServiceArr.slice(4).map((el, goodIndex) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className={styles.item} key={goodIndex}>
            <p className={styles.itemTitle}>{el.title}</p>
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <img
              className={styles.itemImage}
              style={{
                bottom: el.imageBottomMobile,
                width: el.imageMobileWidth,
              }}
              src={el.image}
              alt={el.title}
            />
          </div>
        ))}
      </div>
      <Button
        className={styles.buttonMobile}
        status={BUTTON_STATUSES.NEW_PINK}
        onClick={handleClickunderButtonClick}
      >
        Оформить
      </Button>
    </div>
  );
};
