import React, {useEffect} from 'react';
import { getSearchParams } from '@src/utils/searchParams';
import { getUrl } from '@src/api/SmsService';
import {REDIRECT_BACK_LINK} from '@src/constants';

import {GetMoneyTop} from '@components/LandingPage/getMoneyTop/getMoneyTop';

import {OurProducts} from '@components/LandingPage/ourProducts/ourProducts';

import {GoodService} from '@components/LandingPage/goodService/goodService';

import {CreditLine} from '@components/LandingPage/creditLine/creditLine';

import {SimpleRegistration} from '@components/LandingPage/simpleRegistration/simpleRegistration';

import {IconsBlock} from '@components/LandingPage/iconsBlock/iconsBlock';

import Support from '@src/pages/LandingPage/components/Support';

import { targetHandler } from './targetHelpers';
import styles from './LandingPage.module.scss';

const leadCraftInfo = (lifeTime: number) => {
  const url = new URL(window.location.href);
  if (url.searchParams.get('utm_source') === 'leadcraft') {
    const leadCraftTransactionId = url.searchParams.get('utm_content');
    const leadCraftWm = url.searchParams.get('utm_campaign');
    const _leadCraftTime = new Date(
        (lifeTime || 24 * 60 * 60 * 1000) + new Date().getTime()
    );
    const _leadCraftCS = `leadCraftTransactionId=${leadCraftTransactionId}; path=/; expires=${_leadCraftTime};`;
    document.cookie = _leadCraftCS;
    // // eslint-disable-next-line no-restricted-globals
    // eslint-disable-next-line no-restricted-globals
    document.cookie = `${_leadCraftCS}; domain=.${location.host}`;
    const _leadCraftaffiliate = `leadCraftaffiliate=${leadCraftWm}; path=/; expires=${_leadCraftTime};`;
    document.cookie = _leadCraftaffiliate;
    // // eslint-disable-next-line no-restricted-globals
    // eslint-disable-next-line no-restricted-globals
    document.cookie = `${_leadCraftaffiliate}; domain=.${location.host}`;
  }
};

const bankiInfo = (lifeTime: number) => {
  const url = new URL(window.location.href);
  if (
      url.searchParams.get('utm_source') === 'bankiros' ||
      url.searchParams.get('utm_source') === 'myfin' ||
      url.searchParams.get('utm_source') === 'mainfin'
  ) {
    const bankiTransactionId = url.searchParams.get('utm_content');
    const bankiWm = url.searchParams.get('utm_campaign');
    const _bankiTime = new Date(
        (lifeTime || 24 * 60 * 60 * 1000) + new Date().getTime()
    );
    const _bankiCS = `bankiTransactionId=${bankiTransactionId}; path=/; expires=${_bankiTime};`;
    document.cookie = _bankiCS;
    // // eslint-disable-next-line no-restricted-globals
    // eslint-disable-next-line no-restricted-globals
    document.cookie = `${_bankiCS}; domain=.${location.host}`;
    const _bankiaffiliate = `bankiaffiliate=${bankiWm}; path=/; expires=${_bankiTime};`;
    document.cookie = _bankiaffiliate;
    // // eslint-disable-next-line no-restricted-globals
    // eslint-disable-next-line no-restricted-globals
    document.cookie = `${_bankiaffiliate}; domain=.${location.host}`;
  }
};

const sravniInfo = (lifeTime: number) => {
  const url = new URL(window.location.href);
  if (url.searchParams.get('utm_source') === 'sravni') {
    const sravniTransactionId = url.searchParams.get('click_id');
    const sravniWm = url.searchParams.get('wm_id');
    const _sravniTime = new Date(
        (lifeTime || 24 * 60 * 60 * 1000) + new Date().getTime()
    );
    const _sravniCS = `sravniTransactionId=${sravniTransactionId}; path=/; expires=${_sravniTime};`;
    document.cookie = _sravniCS;
    // // eslint-disable-next-line no-restricted-globals
    // eslint-disable-next-line no-restricted-globals
    document.cookie = `${_sravniCS}; domain=.${location.host}`;
    const _sravniaffiliate = `sravniaffiliate=${sravniWm}; path=/; expires=${_sravniTime};`;
    document.cookie = _sravniaffiliate;
    // // eslint-disable-next-line no-restricted-globals
    // eslint-disable-next-line no-restricted-globals
    document.cookie = `${_sravniaffiliate}; domain=.${location.host}`;
  }
};

const leadsTechInfo = (lifeTime: number) => {
  const url = new URL(window.location.href);
  if (url.searchParams.get('utm_source') === 'leadstech') {
    const leadstechTransactionId = url.searchParams.get('click');
    const leadstechWm = url.searchParams.get('wm');
    const _leadstechTime = new Date(
        (lifeTime || 24 * 60 * 60 * 1000) + new Date().getTime()
    );
    const _leadstechCS = `leadstechTransactionId=${leadstechTransactionId}; path=/; expires=${_leadstechTime};`;
    document.cookie = _leadstechCS;
    // // eslint-disable-next-line no-restricted-globals
    // eslint-disable-next-line no-restricted-globals
    document.cookie = `${_leadstechCS}; domain=.${location.host}`;
    const _leadstechaffiliate = `leadstechaffiliate=${leadstechWm}; path=/; expires=${_leadstechTime};`;
    document.cookie = _leadstechaffiliate;
    // // eslint-disable-next-line no-restricted-globals
    // eslint-disable-next-line no-restricted-globals
    document.cookie = `${_leadstechaffiliate}; domain=.${location.host}`;
  }
};

const masterTargetInfo = (lifeTime: number) => {
  const url = new URL(window.location.href);
  if (url.searchParams.get('utm_source') === 'mastertarget') {
    const masterTransactionId = url.searchParams.get('click_id');
    const masterWm = url.searchParams.get('webmaster_id');
    const _masterTime = new Date(
        (lifeTime || 24 * 60 * 60 * 1000) + new Date().getTime()
    );
    const _masterCS = `masterTransactionId=${masterTransactionId}; path=/; expires=${_masterTime};`;
    document.cookie = _masterCS;
    // // eslint-disable-next-line no-restricted-globals
    // eslint-disable-next-line no-restricted-globals
    document.cookie = `${_masterCS}; domain=.${location.host}`;
    const _masteraffiliate = `masteraffiliate=${masterWm}; path=/; expires=${_masterTime};`;
    document.cookie = _masteraffiliate;
    // // eslint-disable-next-line no-restricted-globals
    // eslint-disable-next-line no-restricted-globals
    document.cookie = `${_masteraffiliate}; domain=.${location.host}`;
  }
};

const getSaleAdsInfo = (lifeTime: number) => {
  const url = new URL(window.location.href);
  if (url.searchParams.get('utm_source') === 'saleads') {
    const saleAdsTransactionId = url.searchParams.get('click');
    const saleWm = url.searchParams.get('wm');
    const _saleTime = new Date(
        (lifeTime || 24 * 60 * 60 * 1000) + new Date().getTime()
    );
    const _saleCS = `saleAdsTransactionId=${saleAdsTransactionId}; path=/; expires=${_saleTime};`;
    document.cookie = _saleCS;
    // // eslint-disable-next-line no-restricted-globals
    // eslint-disable-next-line no-restricted-globals
    document.cookie = `${_saleCS}; domain=.${location.host}`;
    const _saleffiliate = `saleffiliate=${saleWm}; path=/; expires=${_saleTime};`;
    document.cookie = _saleffiliate;
    // // eslint-disable-next-line no-restricted-globals
    // eslint-disable-next-line no-restricted-globals
    document.cookie = `${_saleffiliate}; domain=.${location.host}`;
  }
};

const getUnicomInfo = (lifeTime: number) => {
  const url = new URL(window.location.href);
  if (url.searchParams.get('utm_source') === 'unicom24') {
    const uniComTransactionId = url.searchParams.get('click_id');
    const uniComUser = url.searchParams.get('wm_id');
    const _uniTime = new Date(
        (lifeTime || 24 * 60 * 60 * 1000) + new Date().getTime()
    );
    const _uniCS = `uniTransactionId=${uniComTransactionId}; path=/; expires=${_uniTime};`;
    document.cookie = _uniCS;
    // // eslint-disable-next-line no-restricted-globals
    // eslint-disable-next-line no-restricted-globals
    document.cookie = `${_uniCS}; domain=.${location.host}`;
    const _uniffiliate = `uniAffiliate=${uniComUser}; path=/; expires=${_uniTime};`;
    document.cookie = _uniffiliate;
    // // eslint-disable-next-line no-restricted-globals
    // eslint-disable-next-line no-restricted-globals
    document.cookie = `${_uniffiliate}; domain=.${location.host}`;
  }
};

const getClick2Info = (lifeTime: number) => {
  const url = new URL(window.location.href);
  if (url.searchParams.get('utm_source') === 'click2money') {
    const moneyTransactionId = url.searchParams.get('click_id');
    const moneyUser = url.searchParams.get('user_id');
    const _moneyTime = new Date(
        (lifeTime || 24 * 60 * 60 * 1000) + new Date().getTime()
    );
    const _moneyCS = `moneyTransactionId=${moneyTransactionId}; path=/; expires=${_moneyTime};`;
    document.cookie = _moneyCS;
    // // eslint-disable-next-line no-restricted-globals
    // eslint-disable-next-line no-restricted-globals
    document.cookie = `${_moneyCS}; domain=.${location.host}`;
    const _moneyAffiliate = `moneyAffiliate=${moneyUser}; path=/; expires=${_moneyTime};`;
    document.cookie = _moneyAffiliate;
    // // eslint-disable-next-line no-restricted-globals
    // eslint-disable-next-line no-restricted-globals
    document.cookie = `${_moneyAffiliate}; domain=.${location.host}`;
  }
};

const getLidsTransaction = (lifeTime: number) => {
  const url = new URL(window.location.href);
  if (url.searchParams.get('utm_source') === 'leads.su') {
    const lidsTransactionId = url.searchParams.get('transaction_id');
    const lidsAffiliate = url.searchParams.get('affiliate_id');
    const _lidsTime = new Date(
        (lifeTime || 24 * 60 * 60 * 1000) + new Date().getTime()
    );
    const _lidsCS = `lidsTransactionId=${lidsTransactionId}; path=/; expires=${_lidsTime};`;
    document.cookie = _lidsCS;
    // // eslint-disable-next-line no-restricted-globals
    // eslint-disable-next-line no-restricted-globals
    document.cookie = `${_lidsCS}; domain=.${location.host}`;
    const _leadAffiliate = `lidsAffiliate=${lidsAffiliate}; path=/; expires=${_lidsTime};`;
    document.cookie = _leadAffiliate;
    // // eslint-disable-next-line no-restricted-globals
    // eslint-disable-next-line no-restricted-globals
    document.cookie = `${_leadAffiliate}; domain=.${location.host}`;
  }
};

const getBanksTransactionId = (lifeTime: number) => {
  const url = new URL(window.location.href);
  if (
      url.searchParams.get('transaction_id') &&
      url.searchParams.get('utm_source') === null
  ) {
    const banksTransactionId = url.searchParams.get('transaction_id');
    const _banksTime = new Date(
        (lifeTime || 24 * 60 * 60 * 1000) + new Date().getTime()
    );
    const _banksCS = `banksTransactionId=${banksTransactionId}; path=/; expires=${_banksTime};`;
    document.cookie = _banksCS;
    // // eslint-disable-next-line no-restricted-globals
    // eslint-disable-next-line no-restricted-globals
    document.cookie = `${_banksCS}; domain=.${location.host}`;
  }
};

const _liknotCatcher = (lifeTime: number) => {
  const url = new URL(window.location.href);
  if (
      url.searchParams.get('utm_source') !== 'Leadtarget' &&
      url.searchParams.get('utm_source') !== 'click2money' &&
      url.searchParams.get('utm_source') !== 'unicom24' &&
      url.searchParams.get('utm_medium') !== 'leads.su' &&
      url.searchParams.get('utm_medium') !== 'leadgid' &&
      url.searchParams.get('utm_source') !== 'guruleads' &&
      url.searchParams.get('utm_source') !== 'saleads' &&
      url.searchParams.get('utm_source') !== 'mastertarget' &&
      url.searchParams.get('utm_source') !== 'leadstech' &&
      url.searchParams.get('utm_source') !== 'sravni' &&
      url.searchParams.get('click_id')
  ) {
    const clickIdLiknot = url.searchParams.get('click_id');
    const wmIdLiknot = url.searchParams.get('wmId');
    const _leadTime = new Date(
        (lifeTime || 24 * 60 * 60 * 1000) + new Date().getTime()
    );
    const _liknotCS = `clickIdLiknot=${clickIdLiknot}; path=/; expires=${_leadTime};`;
    document.cookie = _liknotCS;
    // // eslint-disable-next-line no-restricted-globals
    // eslint-disable-next-line no-restricted-globals
    document.cookie = `${_liknotCS}; domain=.${location.host}`;
    const _wmLiknot = `wmLiknot=${wmIdLiknot}; path=/; expires=${_leadTime};`;
    document.cookie = _wmLiknot;
    // // eslint-disable-next-line no-restricted-globals
    // eslint-disable-next-line no-restricted-globals
    document.cookie = `${_wmLiknot}; domain=.${location.host}`;
  }
};

const _leadTargetCatcher = (lifeTime: number) => {
  const url = new URL(window.location.href);
  if (url.searchParams.get('utm_source') === 'Leadtarget') {
    const clickIdTarget = url.searchParams.get('clickid');
    const wmIdTaget = url.searchParams.get('wmid');
    const _leadTime = new Date(
        (lifeTime || 24 * 60 * 60 * 1000) + new Date().getTime()
    );
    const _leadCS = `clickidTarget=${clickIdTarget}; path=/; expires=${_leadTime};`;
    document.cookie = _leadCS;
    // // eslint-disable-next-line no-restricted-globals
    // eslint-disable-next-line no-restricted-globals
    document.cookie = `${_leadCS}; domain=.${location.host}`;
    const _wmLead = `wmTargetId=${wmIdTaget}; path=/; expires=${_leadTime};`;
    document.cookie = _wmLead;
    // // eslint-disable-next-line no-restricted-globals
    // eslint-disable-next-line no-restricted-globals
    document.cookie = `${_wmLead}; domain=.${location.host}`;
  }
};

const _guruCatcher = (lifeTime: number) => {
  const url = new URL(window.location.href);
  if (url.searchParams.get('utm_source') === 'guruleads') {
    const clickIdGuru = url.searchParams.get('click_id');
    const pidGuru = url.searchParams.get('utm_campaign');
    const _leadTime = new Date(
        (lifeTime || 24 * 60 * 60 * 1000) + new Date().getTime()
    );
    const _guruCS = `clickidGuru=${clickIdGuru}; path=/; expires=${_leadTime};`;
    document.cookie = _guruCS;
    // // eslint-disable-next-line no-restricted-globals
    // eslint-disable-next-line no-restricted-globals
    document.cookie = `${_guruCS}; domain=.${location.host}`;
    const _wmGuru = `pidGuru=${pidGuru}; path=/; expires=${_leadTime};`;
    document.cookie = _wmGuru;
    // // eslint-disable-next-line no-restricted-globals
    // eslint-disable-next-line no-restricted-globals
    document.cookie = `${_wmGuru}; domain=.${location.host}`;
  }
};

const _leadGidCatcher = (lifeTime: number) => {
  const url = new URL(window.location.href);
  if (url.searchParams.get('utm_medium') === 'leadgid') {
    const clickIdleadGid = url.searchParams.get('click_id');
    const webLeadGid = url.searchParams.get('web_id');
    const _leadTime = new Date(
        (lifeTime || 24 * 60 * 60 * 1000) + new Date().getTime()
    );
    const _leadGidCS = `clickidLeadGid=${clickIdleadGid}; path=/; expires=${_leadTime};`;
    document.cookie = _leadGidCS;
    // // eslint-disable-next-line no-restricted-globals
    // eslint-disable-next-line no-restricted-globals
    document.cookie = `${_leadGidCS}; domain=.${location.host}`;
    const _webLeadGid = `webLeadGid=${webLeadGid}; path=/; expires=${_leadTime};`;
    document.cookie = _webLeadGid;
    // // eslint-disable-next-line no-restricted-globals
    // eslint-disable-next-line no-restricted-globals
    document.cookie = `${_webLeadGid}; domain=.${location.host}`;
  }
};

const randomLinks = [
  REDIRECT_BACK_LINK,
];
const randomIndex = Math.floor(Math.random() * randomLinks.length);
const link = randomLinks[randomIndex];

const LIFE_TIME = 24 * 60 * 60 * 1000;

const LandingPage = () => {
  useEffect(() => {
    const isExternalReferrer =
        document.referrer && !document.referrer.includes(window.location.origin);

    if (!isExternalReferrer) {
      return;
    }

    window.history.replaceState(null, '', window.location.href);
    window.history.pushState(null, '', window.location.href);

    const handlePopState = () => {
      console.log('Назад нажали! Редиректим...');
      window.ym(87515319,'reachGoal','comeback');
      window.dataLayer.push({'event': 'comeback'});
      window.history.pushState(null, '', window.location.href);
      window.location.href = link;
    };

    window.addEventListener('popstate', handlePopState);

    leadCraftInfo(LIFE_TIME);
    bankiInfo(LIFE_TIME);
    sravniInfo(LIFE_TIME);
    leadsTechInfo(LIFE_TIME);
    masterTargetInfo(LIFE_TIME);
    getSaleAdsInfo(LIFE_TIME);
    getUnicomInfo(LIFE_TIME);
    getClick2Info(LIFE_TIME);
    getLidsTransaction(LIFE_TIME);
    getBanksTransactionId(LIFE_TIME);
    _liknotCatcher(LIFE_TIME);
    _leadTargetCatcher(LIFE_TIME);
    _guruCatcher(LIFE_TIME);
    _leadGidCatcher(LIFE_TIME);
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('utm_source') === 'yandex') {
      window.localStorage.setItem('utm_campaign', urlParams.get('utm_campaign') || '');
      window.localStorage.setItem('utm_content', urlParams.get('utm_content') || '');
    }
    targetHandler();
    (async () => {
      const urlId = getSearchParams('url');
      if (urlId) {
        try {
          const { url } = await getUrl(urlId);
          window.open(url, '_self');
        } catch (e) {
          // @ts-ignore
          console.error(e?.response?.data?.error ?? e);
        }
      }
    })();

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  return (
    <div className={styles.container}>
      <GetMoneyTop />
      <p className={styles.fastRegister}>Быстрая регистрация от 3 до 7 минут</p>
      <OurProducts />
      <GoodService />
      <CreditLine />
      <SimpleRegistration />
      <IconsBlock />
      <Support />
    </div>
  );
};

export default LandingPage;
