import React from 'react';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import { TELEGRAM_LINK, WHATSAPP_LINK, WHATSAPP_LINK_MOBILE } from '@src/constants';
import ProjectNameIconWhite from '@src/assets/images/ProjectNameIconWhite.svg';
import TelegramIcon from '@src/assets/images/TelegramIcon.svg';
import WhatsappIcon from '@src/assets/images/WhatsappIcon.svg';
import EmailIcon from '@src/assets/images/emailIcon.svg';
import PhoneIcon from '@src/assets/images/PhoneIcon.svg';
import BankRussiaSmoothIcon from '@src/assets/images/Footer/BankRussiaSmoothIcon.svg';
import BuroSmoothIcon from '@src/assets/images/Footer/BuroSmoothIcon.svg';
import CloudPaymentsSmoothIcon from '@src/assets/images/Footer/CloudPaymentsSmoothIcon.svg';
import MirSmoothIcon from '@src/assets/images/Footer/MirSmoothIcon.svg';
import OkbSmoothIcon from '@src/assets/images/Footer/okbSmoothIcon.svg';
import ScoristaSmoothIcon from '@src/assets/images/Footer/ScoristaSmoothIcon.svg';
import AlfaSmoothIcon from '@src/assets/images/Footer/alfaSmoothIcon.svg';
import TBankSmoothIcon from '@src/assets/images/Footer/tBankSmoothIcon.svg';

import './Footer.scss';

const feedImages = [
  'https://s3-static-budgett-prod.storage.yandexcloud.net/feeds/plain_1x1_008.jpg',
  'https://s3-static-budgett-prod.storage.yandexcloud.net/feeds/plain_1x1_007.jpg',
  'https://s3-static-budgett-prod.storage.yandexcloud.net/feeds/plain_1x1_006.jpg',
  'https://s3-static-budgett-prod.storage.yandexcloud.net/feeds/plain_1x1_005.jpg',
  'https://s3-static-budgett-prod.storage.yandexcloud.net/feeds/meme_1x1_004.jpg',
  'https://s3-static-budgett-prod.storage.yandexcloud.net/feeds/meme_1x1_003.jpg',
  'https://s3-static-budgett-prod.storage.yandexcloud.net/feeds/meme_1x1_002.jpg',
  'https://s3-static-budgett-prod.storage.yandexcloud.net/feeds/meme_1x1_001.jpg',
];

const partnersArr = [
  {
    title: 'Банк России',
    image: BankRussiaSmoothIcon,
    mobile: {
      width: 96,
      height: 24,
    },
    desktop: {
      width: 165,
      height: 48,
    }
  },
  {
    title: 'CloudPayments',
    image: CloudPaymentsSmoothIcon,
    mobile: {
      width: 33.7,
      height: 28,
    },
    desktop: {
      width: 65,
      height: 48,
    }
  },
  {
    title: 'SCORISTA',
    image: ScoristaSmoothIcon,
    mobile: {
      width: 110.08,
      height: 18,
    },
    desktop: {
      width: 147.11,
      height: 24,
    }
  },
  {
    title: 'Бюро',
    image: BuroSmoothIcon,
    mobile: {
      width: 63.78,
      height: 24,
    },
    desktop: {
      width: 90.32,
      height: 34,
    }
  },
  {
    title: 'ОКБ',
    image: OkbSmoothIcon,
    mobile: {
      width: 53.56,
      height: 24,
    },
    desktop: {
      width: 68,
      height: 30,
    }
  },
  {
    title: 'МИР',
    image: MirSmoothIcon,
    mobile: {
      width: 82.28,
      height: 18,
    },
    desktop: {
      width: 127,
      height: 30,
    }
  },
  {
    title: 'Альфа',
    image: AlfaSmoothIcon,
    mobile: {
      width: 119.44,
      height: 24,
    },
    desktop: {
      width: 119.44,
      height: 24,
    }
  },
  {
    title: 'ТБанк',
    image: TBankSmoothIcon,
    mobile: {
      width: 81.43,
      height: 24,
    },
    desktop: {
      width: 95.01,
      height: 28,
    }
  },
];


const Footer = () => {
  const openSite = () => {
    /* global jivo_api */
    jivo_api.open();
  }

  const today = new Date();
  const year = today.getFullYear()

  return (
    <footer className={classnames('component-footer')}>
      <div className="Width-Wrapper">
        <div className="component-footer__header-top">
          <a href="/#promo">
            <img className="component-footer__header-top__img" src={ProjectNameIconWhite} alt="Budgett" />
          </a>
          <div className="component-footer__header-top__item_links-block">
            <NavLink to="/privacy" className="component-footer__link">
              Политика конфиденциальности
            </NavLink>
            <a href="https://budgett.ru/vidy-zaymov/" className="component-footer__link">
              Виды займов
            </a>
            <NavLink to="/control-documents" className="component-footer__link">
              Контрольные документы
            </NavLink>
            <NavLink to="/finance-documents" className="component-footer__link">
              Финансовые документы
            </NavLink>
            <NavLink to="/sitemap" className="component-footer__link">
              Карта сайта
            </NavLink>
          </div>
        </div>
        <p className="mobile-copyright">ООО МКК «Диджитал Мани», {year}</p>
        <div className="component-footer__mobile-links-block">
          <NavLink to="/privacy"
            className="component-footer__link component-footer__mobile-links-block__item">
            Политика конфиденциальности
          </NavLink>
          <a href="https://budgett.ru/vidy-zaymov/"
            className="component-footer__link component-footer__mobile-links-block__item">
            Виды займов
          </a>
          <NavLink to="/control-documents"
            className="component-footer__link component-footer__mobile-links-block__item">
            Контрольные документы
          </NavLink>
          <NavLink to="/finance-documents"
            className="component-footer__link component-footer__mobile-links-block__item">
            Финансовые документы
          </NavLink>
          <NavLink to="/sitemap" className="component-footer__link component-footer__mobile-links-block__item">
            Карта сайта
          </NavLink>
        </div>
        <div className="component-footer__header-bottom">
          {feedImages.map((image, ) => (
            <img src={image} alt="" key={image} className='component-footer__feed-image'/>
            ))}
          <div className="component-footer__header-bottom__item copyright">ООО МКК «Диджитал Мани», {year}</div>
          <div className="component-footer__header-bottom__item component-footer__header-bottom__item_social-block">
            <p className="component-footer__header-bottom__title">Служба поддержки:</p>
            <div className="component-footer__header-bottom__wrapper">
              <div className="Jivo-Block" onClick={openSite}>
                {window.innerWidth > 1299 ? 'Онлайн чат' : 'Онлайн'}
              </div>
              <a href={TELEGRAM_LINK} className="component-footer__social-link" target="_blank" rel="noreferrer">
                <img className="component-footer__social-link__img" src={TelegramIcon} alt="Telegram"/>
              </a>
              <a href={window.innerWidth > 850 ? WHATSAPP_LINK : WHATSAPP_LINK_MOBILE}
                className="component-footer__social-link" target="_blank" rel="noreferrer">
                <img className="component-footer__social-link__img" src={WhatsappIcon} alt="Whatsapp"/>
              </a>
              <a href="mailto:support@budgett.ru" className="component-footer__social-link" target="_blank"
                rel="noreferrer">
                <img className="component-footer__social-link__img" src={EmailIcon} alt="email"/>
              </a>
              <a href="tel:88005559980" className="component-footer__social-link" target="_blank"
                rel="noreferrer">
                <img className="component-footer__social-link__img" src={PhoneIcon} alt="email"/>
              </a>
            </div>
          </div>
        </div>
        <p className="component-footer__layerinfo">Общество с ограниченной ответственностью Микрокредитная компания
          “Диджитал Мани”, ИНН 5445030457, КПП 544501001, ОГРН 1195476084968, Номер счёта: 40701810423370000004, Банк: ФИЛИАЛ "НОВОСИБИРСКИЙ" АО "АЛЬФА-БАНК", БИК: 045004774, Кор. счёт: 30101810600000000774, номер в Государственном реестре ЦБ 2004150009570, номер
          в едином реестре СРО 54 001155. Юридический адрес: 633011, Новосибирская область, город Бердск, улица Островского, дом 55, офис 21. Все права защищены.</p>
        <div className="component-footer__partners-block">
          <div className="component-footer__partners-block__title">
            Наши партнеры:
          </div>
          <div className="component-footer__partners-block__content">
            {partnersArr.map((el) => {
              const inlineStyles = {
                '--widthMobile': `${el.mobile.width}px`,
                '--heightMobile': `${el.mobile.height}px`,
                '--widthDesktop': `${el.desktop.width}px`,
                '--heightDesktop': `${el.desktop.height}px`,
              };

              return (
                <img className={classnames('partnerImage')} style={inlineStyles} src={el.image} width={165} height={48} alt={el.title} />
              )
            })}
          </div>
        </div>
      </div>
    </footer>
  )};

export default Footer;
