import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '@src/store/ducks/auth';
import LogoIcon from '@src/assets/images/header_logo.svg';
import Button, { BUTTON_STATUSES } from '@src/components/Button';
import { ACCOUNT_MODERATION_STATUSES } from '@src/constants';
import PowerOffIcon from '@src/assets/images/PowerOffIcon.svg';
import ProfileIcon, { ReactComponent as ProfileIconButton } from '@src/assets/images/ProfileIcon.svg';
import './Header.scss';
import Modal from 'react-modal';

import MobileWrapper from '@src/components/MobileWrapper/MobileWrapper';


const Header = () => {
  const [showBottomBlock, setShowBottomBlock] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated, accountModerationStatus } = useSelector(({ auth, profile }) => ({
    isAuthenticated: auth.isAuthenticated,
    accountModerationStatus: profile.accountModerationStatus,
  }));

  const customStyles = {
    overlay: {
      top: '0',
      left: '0',
      right: '0',
      bottom: 'calc((150 * 100vw) / 720)',
      zIndex: 10000,
      backgroundColor: 'rgba(28, 42, 64, 0.4)',
      display: 'flex',
      alignItems: 'end',
    },
    content: {
      position: 'relative',
      inset: 'inherit',
      border: 'none',
      background: 'none',
      overflow: 'inherit',
      borderRadius: 0,
      outline: 'none',
      padding: 0,
      height: 'fit-content',
      width: '100%',
    }
  };

  const handleLogout = useCallback(async () => {
    dispatch(
      logout()
    );
  }, []);

  const showTopBlockHandler = () => {
    setShowBottomBlock(true);
    document.body.style.overflow = 'hidden';
  }

  const handleCloseTopBlock = () => {
    setShowBottomBlock(false);
    document.body.style.overflow = '';
  }

  return (
    <header className="component-header">
      <div className="Width-Wrapper Content-Block">
        <a href="/#promo" className="logo">
          <img className="logo__main" src={LogoIcon} alt="Logo" />
        </a>
        {(isAuthenticated && accountModerationStatus !== ACCOUNT_MODERATION_STATUSES.REGISTERED) ? (
          <div className="auth-icons">
            <a href="/profile/add-contract" className="profile-block">
              <div className="icon">
                <img src={ProfileIcon} alt="Profile" />
              </div>
            </a>
            <a href="#" className="logout-block" onClick={handleLogout}>
              <div className="icon">
                <img src={PowerOffIcon} alt="Logout" />
              </div>
              <div className="text">Выйти</div>
            </a>
          </div>
        ) : (isAuthenticated && accountModerationStatus === ACCOUNT_MODERATION_STATUSES.REGISTERED) ? (
          (window.innerWidth >= 1080 )? (
            <div className="auth-icons auth-icons-openModal">
              <a className="Back-to-reg" href='/sign_up'>Завершить регистрацию</a>
              <a href="#" className="logout-block" onClick={handleLogout} >
                <div className="text-button">Выйти</div>
              </a>
            </div>
          ) : (
            <Button
              onClick={showTopBlockHandler}
              status={BUTTON_STATUSES.DEFAULT}
              >
              <div className="icon">
                <ProfileIconButton/>
              </div>
            </Button>
          )
        ) : (
          <Button
            onClick={() => navigate('/login')}
            status={BUTTON_STATUSES.HEADER}
            >
            <div className="text">Личный кабинет</div>
            <div className="icon">
              <ProfileIconButton/>
            </div>
          </Button>
        )}
      </div>
      <Modal
        isOpen={showBottomBlock}
        onRequestClose={handleCloseTopBlock}
        style={customStyles}
      >
        <MobileWrapper hide={handleCloseTopBlock}>
          <div className="buttons-block">
            <div className="buttons-container">
              <a className="Back-to-reg" href='/sign_up'>Завершить регистрацию</a>
              <a href="#" className="logout-block" onClick={handleLogout} >
                <div className="text-button">Выйти</div>
              </a>
            </div>
            <div onClick={handleCloseTopBlock} className="close-bottom-block" />
          </div>
        </MobileWrapper>
      </Modal>
    </header>
  )
};

export default Header;
