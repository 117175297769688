import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Loader from '@src/pages/SignUpV2/components/Loader';

import LandingPage from '@src/pages/LandingPage';
import LandingLayout from '@src/layouts/LandingLayout';
import MailingPage from '@src/pages/MailingPage';

const AnonymousOnlyRoute = lazy(() => import('@components/AnonymousOnlyRoute'));
const LoginPage = lazy(() => import('@src/pages/Login'));
const DeleteAccountSuccessPage = lazy(
  () => import('@src/pages/DeleteAccountSuccess')
);
const FinanceDocumentsPage = lazy(() => import('@src/pages/FinanceDocuments'));
const ControlDocumentsPage = lazy(() => import('@src/pages/ControlDocuments'));
const SiteMapPage = lazy(() => import('@src/pages/SiteMap'));
const PrivateRoute = lazy(() => import('@components/PrivateRoute'));
const MainLayout = lazy(() => import('@src/layouts/MainLayout'));
const EmptyLayout = lazy(() => import('@src/layouts/EmptyLayout'));
const MainLayoutNoFooter = lazy(
  () => import('@src/layouts/MainLayoutNoFooter')
);
const ProfileRoutes = lazy(
  () => import('@src/router/routes/profile/ProfileRoutes')
);
const SignUpRoutes = lazy(
  () => import('@src/router/routes/sign-up/SignUpRoutes')
);
const PrivacyPolicy = lazy(() => import('@src/pages/PrivacyPolicy'));

declare global {
  interface Window {
    ym: any;
    dataLayer: any;
    _tmr: any;
    ymab: any;
  }
}

// @ts-ignore
const NotFound = () => {
  window.location.href = '../../404.html';

  return null;
};

export default function Router() {
  return (
    <BrowserRouter>
      <div>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route element={<LandingLayout />}>
              <Route path="/" element={<LandingPage />} />
            </Route>
            <Route element={<MainLayout />}>
              <Route path="*" element={<NotFound />} />
              <Route path="privacy" element={<PrivacyPolicy />} />
              <Route
                path="finance-documents"
                element={<FinanceDocumentsPage />}
              />
              <Route
                path="control-documents"
                element={<ControlDocumentsPage />}
              />
            </Route>
            <Route path="/mailing" element={<MailingPage />} />
            <Route element={<MainLayoutNoFooter />}>
              <Route path="sitemap" element={<SiteMapPage />} />
            </Route>
            <Route element={<EmptyLayout />}>
              <Route element={<AnonymousOnlyRoute />}>
                <Route path="login" element={<LoginPage />} />
                <Route path="resetPassword" element={<LoginPage />} />
                <Route
                  path="account-deleted"
                  element={<DeleteAccountSuccessPage />}
                />
              </Route>
            </Route>
            <Route element={<PrivateRoute />}>
              <Route path="profile/*" element={<ProfileRoutes />} />
            </Route>
            <Route path="sign_up/*" element={<SignUpRoutes />} />
          </Routes>
        </Suspense>
      </div>
    </BrowserRouter>
  );
}
