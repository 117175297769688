import React from 'react';
import TelegramIcon from '@src/assets/images/TelegramIcon.svg';
import EmailIcon from '@src/assets/images/emailIcon.svg';
import { TELEGRAM_LINK, WHATSAPP_LINK, WHATSAPP_LINK_MOBILE } from '@src/constants';
import className from 'classnames/bind';


import WhatsappIcon from '@src/assets/images/WhatsappIcon.svg';

import PhoneIcon from '@images/PhoneIcon.svg';

import styles from './Footer.module.scss';

const cx = className.bind(styles);

const Footer = ({ withBackground }: {withBackground: boolean}) => {

  const footerClasses = cx({
    FooterBlockBg: withBackground,
    FooterBlockDefault: true,
  });

  const openSite = () => {
    // @ts-ignore
    window.jivo_api.open();
  };

  const today = new Date();
  const year = today.getFullYear()

  return (
    <footer className={footerClasses}>
      <div className={styles.InnerFooter}>
        <div className={styles.LeftSection}>
          <span>ООО МКК «Диджитал Мани», {year}</span>
        </div>
        <div className={styles.RightSection}>
          <div className={styles.DivLine} />
          <p className={styles.ChatText}>Служба поддержки:</p>
          <div className={styles.wrapper}>
            <div className={styles.JivoBlock} onClick={openSite}>
              {window.innerWidth < 850 ? 'Онлайн' : 'Оператор Онлайн'}
            </div>
            <a href={TELEGRAM_LINK} className="component-footer__social-link" target="_blank" rel="noreferrer">
              <img className="component-footer__social-link__img" src={TelegramIcon} alt="Telegram"/>
            </a>
            <a href={window.innerWidth > 850 ? WHATSAPP_LINK : WHATSAPP_LINK_MOBILE}
              className="component-footer__social-link" target="_blank" rel="noreferrer">
              <img className="component-footer__social-link__img" src={WhatsappIcon} alt="Whatsapp"/>
            </a>
            <a href="mailto:support@budgett.ru" className="component-footer__social-link" target="_blank"
              rel="noreferrer">
              <img className="component-footer__social-link__img" src={EmailIcon} alt="email"/>
            </a>
            <a href="tel:88005559980" className="component-footer__social-link" target="_blank"
              rel="noreferrer">
              <img className="component-footer__social-link__img" src={PhoneIcon} alt="email"/>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
